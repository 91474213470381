#ContactUs {
    margin-top: 3rem;
    .contactUsColumn {
        padding: 2rem 5px;
        .contactUsSurrounding {
            position: relative;
            border: 3px solid $rkw-light-blue;
            border-radius: 20px;
            height: 100%;
            padding: 2rem 1rem 1rem;
            .headingSurrounding {
                position: absolute;
                top: -26px;
                left: 0;
                width: 100%;
                h3 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #fff;
                    font-family: montserrat-bold;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 1rem;
                    margin-bottom: 0;
                    border: 3px solid $rkw-light-blue;
                    border-radius: 25px;
                    height: 50px;
                    width: 300px;
                    margin: auto;
                }
            }
            .boxHeading {
                font-size: 1rem;
                text-align: center;
                text-transform: uppercase;
            }
            .boxSurrounding {
                display: flex;
                justify-content: center;
                a {
                    color: #000;
                }
                .phoneIcon, .emailIcon {
                    width: 15px;
                    margin-right: 5px;
                }
                .phoneSpan, .emailSpan {
                    font-family: montserrat-bold;
                }
            }
            .contactUsButton {
                background-color: $rkw-light-blue;
                border-radius: 20px;
                border: none;
                width: 200px;
                height: 40px; 
                a {
                    color: #fff;
                    text-decoration: none;
                    padding: 12px 12px;
                }
            }
            .accountRegistrationButton {
                background-color: $rkw-light-blue;
                border-radius: 20px;
                border: none;
                width: 200px;
                height: 40px; 
                a {
                    color: #fff;
                    text-decoration: none;
                    padding: 12px 20px;
                }
            }
            .subHeading {
                text-align: center;
                font-weight: 700;
                font-size: 1.1rem;
                color: #4a4a4f;
            }
            .facebookIcon,
            .twitterIcon,
            .linkedInIcon {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $rkw-light-blue;
                width: 50px;
                height: 50px;
                border-radius: 25px;
                margin: 10px;
                a {
                    color: #fff;
                    font-size: 1.8rem;
                }
            }
            ul {
                min-height: 120px;
                list-style-type: none;
                padding: 0;
                text-align: center;
            }
        }
    }
    .socialMediaColumn {
        .contactUsSurrounding {
            border: 3px solid $rkw-dark-blue !important;
            h3 {
                border: 3px solid $rkw-dark-blue !important;
            }
        }
    }
    .showroomColumn {
        .contactUsSurrounding {
            border: 3px solid #000 !important;
            h3 {
                border: 3px solid #000 !important;
            }
        }
    }
    .officeHoursText {
        font-weight: 700;
    }
}

@media only screen and (min-width: 992px) {
    .contactUsColumn {
        padding: 2rem !important;
    }
}

@media only screen and (min-width: 992px) {
    .contactUsColumn {
        h3 {
            font-size: 1.1rem !important;
            width: 400px !important;
        }
    }
}

