#RKWChina {
    margin-top: 3rem;
    .RKWChinaTextRow {
        margin: 4rem 0 2rem;
        .rkwChinaText {
            text-align: center;
            font-size: .9rem;
        }
    }
    .mapImage {
        height: 90px;
    }
    .clickToEnlargeText {
        text-align: center;
        color: #9d9d9d;
        .enlargeImageIcon {
            width: 14px;
        }
    }
}