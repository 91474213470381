/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

$rkw-dark-blue: #032c62;
$rkw-light-blue: #51a3d5;

#tabsContainer {
    #tabsRow {
        width: 100%;
        .card-body {
            height: unset;
            .orderItemsWrapper {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                        justify-content: space-between;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                margin: 20px 0 5px;
                color: #4a4a4f;
                .card-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin: 0;
                    height: 65px;
                    h6 {
                        font-family: montserrat-bold;
                        font-size: .9rem;
                        color: $rkw-dark-blue;
                    }
                    p {
                        font-size: 0.9em;
                        margin: 0 0 .5rem;
                    }
                    .fa-shipping-fast,
                    .fa-warehouse,
                    .fa-clipboard-list,
                    .fa-check {
                        font-size: 1.5em;
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                    .fa-check {
                        color: #74ff0b;
                    }
                }
                .orderPlacedIcons {
                    flex-direction: row;
                    align-items: center;
                    .status {
                        color: #01b5dd;
                    }
                }
                .orderReference {
                    width: 110px;
                }
            }
            .orderSummaryButton {
                background-color: $rkw-light-blue;
                float: center;
                font-size: 0.7rem;
                border-radius: 10px;
                color: #fff;
                border: none;
                padding: 7px 40px;
            }
            .ordersDeliveredWrapper {
                width: 85%;
                margin: 20px auto 5px;
            }
        }
        .orderDivider {
            height: 2px;
            width: 80%;
            margin: 0% auto;
            border: none;
            background-color: #b1b1b1;
            border-radius: 50%;
        }
    }
}


@media only screen and (max-width: 767px) {
    #tabsContainer {
        .card-text {
            display: block !important;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 991px) {
    #tabsContainer {
        .orderItemsWrapper {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            .card-text {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                width: 100%;
                height: 100px;
            }
            .card-text p:nth-child(1) {
                font-weight: bold;
            }
        }
        .ordersDeliveredWrapper {
            width: 100%;
        }
    }
    .icons {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
    }
}

@media only screen and (max-width: 1199px) {
    #tabsContainer {
        #tabsRow {
            width: 100%;
            .card-body {
                .card-text {
                    margin: 0;
                    p {
                        font-size: 0.8em;
                    }
                }
            }
        }
    }
}