/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

#top {
    height: 114px;
}

.scrolling {
    top: 95px;
}

#mainNavBar {
    position: fixed;
    font-size: 14px;
    z-index: 1000;
    top: 114px;
    #navBarContainer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: $rkw-dark-blue;
        margin: auto;
        min-width: 960px;
        width: 82%;
        position: relative;
        .mainMenu {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: space-around;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin: 0;
            height: 44px;
            width: 100%;
            .navbarLeftTriangle {
                clip-path: polygon(10% 0, 100% 0, 100% 100%);
                background-color: $rkw-dark-blue;
                height: 45px;
                width: 24px;
                position: absolute;
                background-color: #142c62;
                left: -23px;
            }
            .navbarRightTriangle {
                clip-path: polygon(0 0, 90% 0, 0 100%);
                background-color: $rkw-dark-blue;
                height: 45px;
                width: 24px;
                position: absolute;
                background-color: #142c62;
                right: -23px;
            }
            .firstTeirItem {
                text-transform: uppercase;
                list-style-type: none;

                height: 100%;
                padding-top: 11px;
                .navbarHeading {
                    color: white;
                    font-size: 0.85rem;
                    padding: 1px calc(4.005vw - 51px);
                    a {
                        font-size: 11px;
                        font-weight: bold;
                    }
                }
                #menuSubOffers {
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    width: 18vw;
                    min-width: 18vw;
                    max-width: 18vw;
                    left: calc(79.15vw - 208px);
                }
                .menuSub {
                    position: absolute;
                    background-color: white;
                    top: 44px;
                    min-width: 80vw;
                    max-width: 90vw;
                    left: 0;
                    color: black;
                    margin-left: 0vw;
                    padding-bottom: 30px;
                    .secondTeirItem {
                        list-style-type: none;
                        font-size: 1.1rem;
                        color: black;
                        text-transform: capitalize;
                        .navbarSubheading {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: flex-end;
                            height: 50px;
                            h5 {
                                margin-bottom: -15px;
                                font-size: 0.9rem;
                                font-weight: 600;
                            }
                        }

                        .thirdTeirItem {
                            list-style-type: none;
                            font-size: 0.7em;
                            padding-bottom: 5px;
                            cursor: context-menu;
                            .thirdTeirItemLink {
                                color: black;
                                text-decoration: none;
                            }
                        }
                        .thirdTeirItemMicrowave {
                            width: 100px;
                        }
                        .secondDropdown {
                            background-color: #fff;
                            z-index: 1000;
                            color: black;
                            -webkit-box-shadow: 3px 2px 4px #000;
                            box-shadow: 3px 2px 4px #000;
                            width: 150%;
                            left: 93%;
                            top: -17px;
                            font-size: 1.4em;
                            padding-left: 10px;
                        }
                    }
                    .offersDropdown:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }

            .navbarItemDivider {
                width: 1px;
                background-color: $rkw-light-blue;
                height: 20px;
            }

            .noVisibleDropdown {
                display: none;
            }
            .visibleDropdownHousewares {
                display: block;
            }
            .navbarHeading:hover {
                color: #000;
            }
            // .firstTeirItem:hover {
            //     background-color: #fff;
            // }
            .linkToClick {
                color: #000;
            }
            .level2HR {
                background: #16a6d9;
                height: 1px;
                // margin-top: 5px;
            }
            .level2Text {
                font-weight: bold;
                font-size: 15px;
            }
            .linkToClickTopLevel {
                color: #fff;
            }
            .chevronRight {
                font-size: 0.8em;
            }
            .brandImageMegaMenu {
                height: 45px;
            }
            .navbarBrand {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
            }
        }

        .dropdown-menu {
            -webkit-transform: translate3d(0px, -3px, 0px) !important;
            transform: translate3d(0px, -3px, 0px) !important;
            max-height: 80vh;
            overflow-y: auto;
            a {
                color: black;
                font-size: 10px;
            }
        }
    }
}

.level2Row {
    max-height: 65vh;
}

@media (max-width: 991px) {
    #mainNavBar {
        display: none;
    }
    .telephoneNumber {
        display: none;
    }
}

@media (max-width: 1300px) {
    .navbarHeading {
        a {
            font-size: 11px !important;
        }
    }
}
