.brands {
    padding-bottom: 80px;
    .row, .col {
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .towerImage {
        width: 100%;
        float: right;
    }
    .brandsRow {
        height: calc(100% - 40px);
        .brandsFirstCol,
        .brandsSecondCol {
            border-bottom: 1px solid $rkw-dark-blue;
        }
        .brandsFirstCol,
        .brandsThirdCol {
            border-right: 1px solid $rkw-dark-blue; 
        }
        .brandImage {
            height: 35vw;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
    
    .buttonRow {
        a {
            padding-top: 10px;
            padding-bottom: 10px;
            .brandsButton {
                background-color: $rkw-dark-blue;
                color: #fff;
                border: none;
                width: 100%;
                padding-top: 10px;
                padding-bottom: 10px;
                height: 40px;
            }
            .brandsButton:hover {
                text-decoration: underline;
            }
        }
    }
    @media only screen and (min-width: 992px) {
        .brandImage {
            height: 100% !important;
        }
    }
}

