.categorySlider {
    margin: 0;
    .categoryItem {
        padding: 3px;
        .categoryCard {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: $rkw-dark-blue;
            height: 250px;
            a {
                color: #fff;
                .categoryImage {
                    height: 127px;
                    margin: auto;
                }
                p {
                    font-size: 1rem;
                    padding: 10px;
                }
            }
        }
    }
    .slick-prev {
        left: 15px;
        z-index: 10;
    }
    .slick-next {
        right: 15px;
        z-index: 10;
    }
    .slick-prev:before {
        position: absolute;
        top: 2px;
        right: 4px;
        font-family: 'slick';
        font-size: 30px;
        line-height: 50%;
        margin-left: -2%;
        opacity: 99.75;
        color: #fff;
        padding: 0;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
    }
    .slick-next:before {
        position: absolute;
        top: 2px;
        left: 4px;
        font-family: 'slick';
        font-size: 30px;
        line-height: 50%;
        margin-left: -2%;
        opacity: 99.75;
        color: #fff;
        padding: 0;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
    }
}


@media only screen and (min-width: 1199px) {
    .categoryImage {
        height: 145px !important;
    }
}

