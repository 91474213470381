/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

#brandCarousel {
    margin: auto;
    padding-bottom: 1.4vw;
    .brandHeading {
        color: $rkw-dark-blue;
        font-weight: 700;
    }
    .brandHeadingDivider {
        width: 60px;
        height: 2px;
        background-color: $rkw-light-blue;
    }
    .brandImage {
        width: 100%;
        padding: 15px;
        -webkit-filter: grayscale(1);
                filter: grayscale(1);
        opacity: 0.4;
        cursor: pointer;
        -webkit-transition: opacity 0.2s ease-in, -webkit-filter 0.2s ease-in;
        transition: opacity 0.2s ease-in, -webkit-filter 0.2s ease-in;
        -o-transition: filter 0.2s ease-in, opacity 0.2s ease-in;
        transition: filter 0.2s ease-in, opacity 0.2s ease-in;
        transition: filter 0.2s ease-in, opacity 0.2s ease-in, -webkit-filter 0.2s ease-in;
    }
    .brandImage:hover {
        -webkit-filter: none;
                filter: none;
        opacity: 1;
    }
    .slick-prev:before {
        position: absolute;
        top: 2px;
        left: 25px;
        font-family: 'slick';
        font-size: 30px;
        line-height: 50%;
        margin-left: -2%;
        opacity: 99.75;
        color: rgb(79, 153, 190);
        padding: 0;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
    }
    .slick-next:before {
        position: absolute;
        top: 2px;
        right: 25px;
        font-family: 'slick';
        font-size: 30px;
        line-height: 50%;
        margin-left: -2%;
        opacity: 99.75;
        color: rgb(79, 153, 190);
        padding: 0;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
    }
}