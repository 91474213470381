#WhoWeAre {
    margin-top: 3rem;
    .whoWeAreText {
        text-align: center;
        font-size: .9rem;
        .missionKeyPoints {
            font-family: montserrat-bold;
            color: $rkw-dark-blue;
        }
    }
    .whoWeAreLogo {
        width: 100px;
    }
    .whoWeAreSliderHeading {
        font-family: montserrat-bold;
        text-align: center;
        text-transform: uppercase;
        margin: 4rem 0 2rem;
        width: 100%;
        font-size: 1.3rem;
    }
}
