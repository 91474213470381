
.loginFormModal {
    .formLabel {
        color: #aaa;
    }
    .forgottenPwd {
        color: black;
        text-decoration: underline;
        text-align: center;
        cursor: pointer
    }
    .loginPageButton {
        background-color: $rkw-dark-blue;
        color: #fff;
        border: none;
        width: 100%;
        height: 40px;
        font-size: 1rem;
        margin-top: 1.5rem;
        margin-bottom: .5rem;
        font-size: .8rem;
    }
}
