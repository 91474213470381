#deliveryInformation {
    margin-top: 3rem;
    .deliveryHeading {
        font-size: 1rem;
        text-align: center;
    }
    .deliveryColumn {
        position: relative;
        margin-bottom: 3rem;
        .deliveryColumnSurrounding {
            border: 4px solid $rkw-dark-blue;
            border-radius: 20px;
            height: 100%;
            .deliveryImageContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-color: #fff;
                top: -40px;
                left: calc(50% - 40px);
                width: 80px;
                height: 80px;
                border: 4px solid $rkw-dark-blue;
                border-radius: 50px;
                .deliveryImage {
                    height: 53%;
                }
            }
            .textContainer {
                padding: 40px 10px 20px;
                text-align: center;
                h4 {
                    font-family: montserrat-bold;
                    font-size: 1rem;
                }
                .muted {
                    font-size: .7rem;
                    font-weight: 700;
                    color: $rkw-dark-blue;
                }
            }
        } 
    }
}
