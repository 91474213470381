#WhoWeAreSlider {
    .slick-slider {
        margin-bottom: 6rem;
        position: relative;
        .timelineItem {
            position: relative;
            padding: 9rem 0;
            .timeline {
                position: absolute;
                background-color: #000;
                height: 3px;
                width: 100%;
                top: 44.5%;
                z-index: -1;
            }
            .firstTimelineItem {
                width: 50%;
                right: 0;
            }
            .lastTimelineItem {
                width: 50%;
            }
            .timelineItemCircle {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                border: 4px solid #000;
                width: 30px;
                height: 30px;
                border-radius: 15px;
                cursor: pointer;
                margin: auto;
                z-index: 1;
                .timelineItemInnerCircle {
                    background-color: $rkw-light-blue;
                    height: 10px;
                    width: 10px;
                    border-radius: 5px;
                }
            }
            .timelineItemDate {
                font-family: montserrat-bold;
                color: $rkw-dark-blue;
            }
            .timelineItemText {
                font-family: montserrat-bold;
            }
        }
        .slick-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border: 4px solid #000;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            z-index: 1;
        }
        .slick-prev:before {
            content: '\25C0' !important;
            color: $rkw-dark-blue;
            line-height: 12px;
        }
        .slick-next:before {
            content: '\25BA' !important;
            color: $rkw-dark-blue;
            margin-left: 5px;
            line-height: 12px;
        }
    }
}