/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

.modal {
    margin-top: 10vh;
    .bodyStyle {
        font-size: 1.1rem;
        padding: 0 20px;
        text-align: center;
        overflow-y: scroll !important;
        .orderSummaryHeading {
            border-bottom: .2px solid #dadada;
        }
        .orderSummaryItem {
            padding: 15px 0;
            border-bottom: .2px solid #dadada;
            .itemInBasketImage {
                width: 150px;
            }
            .allItemsDescription {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
            }
            .textMuted {
                font-size: 0.8rem;
                color: grey;
            }
        }
    }
    
    .noBorder {
        border: none;
    }
    
    .invisibleButton {
        visibility: hidden;
    }
    
    .modal-content {
        border-radius: 20px;
    }
}