#policies {
    margin-top: 3rem;
    .titles {
        margin-bottom: 0px;
        font-weight: bold;
        font-size: 1rem;
    }
    .subtitles {
        margin-bottom: 0px;
        font-weight: bold;
        font-size: 0.9rem;
    }
    .postAddress {
        list-style: none;
    }
    .modernSlaveryPolicyContainer {
        a {
          color: #000;
          opacity: 0.4;
          -webkit-transition: opacity 0.17s ease-in;
          -o-transition: opacity 0.17s ease-in;
          transition: opacity 0.17s ease-in;
        }
        a:hover {
          opacity: 0.9;
        }
    }
    .pageHeading {
        font-weight: 700;
        font-size: 1.3rem;
        margin-bottom: 3rem;
        text-align: center;
    }
}