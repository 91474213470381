/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

.sideDrawer {
    position: fixed;
    top: 2px;
    right: 0;
    width: 70%;
    max-width: 400px;
    height: 100vh;
    background-color: #fff;
    z-index: 1000;
    overflow-y: scroll;
    -webkit-box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.3);
            box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.3);
    -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    font-size: 1.3em;
    padding-bottom: 8.5rem;
    .closeIconRow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        color: grey;
        .closeIconCol {
            max-width: 115px;
            cursor: pointer;
        }
    }
}

.sideDrawActive {
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
}


@media only screen and (min-width: 992px) {
    .sideDrawer {
        display: none;
    }
}