#accountIndexWrapper {
  font-size: 14px;
  margin: 0 auto;
  padding-bottom: 75px;
  .inividualWrapper {
    margin-top: 15px;
  }
  #firstCol {
    h2 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0%;
    }
  }
  
  .needAssistanceCard {
    height: 194px;
  }
  #secondCol {
    #customerNumberWrapper {
      float: left;
    }
  }
  .accountHeadingDivider {
    width: 80%;
    max-width: 400px;
    height: 3px;
    background-color: $rkw-light-blue;
    border: none;
    border-radius: 1px;
  }
  .card {
    display: flex;
    justify-content: center;
    border: 3px solid $rkw-light-blue;
    border-radius: 27px;
    text-align: center;
    .cardTitleWrapper {
      width: 100%;
      position: absolute;
      top: -26px;
      .card-title {
        width: 227px;
        border: 3px solid $rkw-light-blue;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 27px;
        text-align: center;
        font-family: montserrat-bold;
        margin: auto;
        background-color: #fff;
      }
    }
    .card-body {
      padding-top: 30px;
      height: 170px;
      word-break: break-all;
      .nameWrapper {
        padding: 5px 7px;
        display: inline-block;
        font-size: 14px;
      }
      .card-text {
        height: 90px;
      }
      a {
        font-family: montserrat-bold;
        color: #000;
        .phoneIcon, .emailIcon {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
    .phoneIcon, .emailIcon {
      width: 15px;
      margin-right: 5px;
    }
  }

  .needAssistanceCardCol, .changedAddressCardCol {
    .card {
      border: 3px solid $rkw-dark-blue;
      .cardTitleWrapper {
        .card-title {
          border: 3px solid $rkw-dark-blue;
        }
      }
      span {
        word-break: break-word;
      }
    }
  }
  
  .addressFields ul {
    list-style: none;
  }
  
  #iconCol {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      font-size: 30px;
    }
  }
}
.accountLinks {
  width: 200px;
  text-decoration: none !important;
  .accountButtonSurrounding {
    display: flex;
    justify-content: center;
    .accountButton {
      width: 150px;
      padding: 5px;
      border-radius: 20px;
      border: 3px solid $rkw-dark-blue;
      color: $rkw-dark-blue;
      font-family: montserrat-bold;
      transition: transform 0.1s ease-in box-shadow 0.1s ease-in;
    }
  }
}

.account-page-active .accountButton {
  background-color: $rkw-dark-blue;
  color: #fff !important;
}

.hidden-card {
  visibility: hidden;
  display: none;
}

#tabsContainer {
  position: relative;
  .nav {
    position: absolute;
    display: flex;
    justify-content: space-around;
    width: 100%;
    top: -20px;
    z-index: 1;
    border-bottom: none;
    a {
      background-color: #fff;
      border-radius: 20px;
      border: 3px solid $rkw-dark-blue;
      font-family: montserrat-bold;
      width: 50%;
      max-width: 145px;
      text-align: center;
      color: #000;
    }
    .active {
      background-color: $rkw-light-blue;
      color: #fff;
      font-family: montserrat-bold;
    }
  }
  .tab-content {
    background-color: transparent;
    .card {
      border: 3px solid $rkw-dark-blue;
    }
  }
}


.pleaseEnterPONumberActive {
  font-size: 0.73rem;
  color: red;
  display: block !important;
}
.pleaseEnterPONumber {
  display: none;
}

.POBoxRedShadow {
  border-color: #ff828e;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}


.deliveryInformationField {
  height: 110px;
  width: 100%;
  font-size: 16px;
}


