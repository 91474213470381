#stayConnected {
    h2 {
        color: #fff;
        font-weight: 700;
    }
    .socialIcons {
        a {
            color: #fff;
            font-size: 1.4rem;
            transition: all .1s ease-in;
            border-radius: 5px;
            padding: 2px;
            .socialMediaIcon {
                width: 30px;
            }
        }
    }
    .facebookIcon>a:hover {
        background-color: #4267B2;
    }
    .twitterIcon>a:hover {
        background-color: #1DA1F2;
    }
    .linkedInIcon>a:hover {
        background-color: #0e76a8;
    }
    .socialIcons>span {
        padding-left: 15px;
        padding-right: 15px;
    }
}