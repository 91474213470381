#subscribe {
    position: relative;
    color: #fff;
    bottom: 0;
    .subscribeOverlay {
        background: linear-gradient(45deg, $rkw-dark-blue, $rkw-light-blue);
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        .spiralImage02 {
            position: absolute;
            height: 230px;
            bottom: 0;
            right: 0;
        }
    }
    .subscribeSection {
        .subscribeText {
            font-size: 1rem;
            z-index: 2;
        }
        .subscribeForm {
            width: 100%;
            max-width: 493px;
            z-index: 2;
            input {
                background-color: #ddd;
                border: none;
                height: 30px;
                width: calc(100% - 35px);
            }
            button {
                background-color: #fff;
                color: $rkw-dark-blue;
                height: 30px;
                width: 35px;
                border: none;
            }
        }
    }
    .subscribeMessage {
        width: 90%;
        max-width: 700px;
        margin: auto;
        p {
            z-index: 2;
            margin-bottom: 0;
            a {
                color: #fff;
            }
        }
    }
}