/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

#deliveryStrapline {
  background-color: $rkw-dark-blue;
  .col {
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
    a {
      color: #fff;
      h3 {
        font-size: 1.5rem;
        color: white;
        margin-bottom: 0px;
        svg {
          margin-right: 5px;
          font-size: 1.5rem;
        }
      }
    }
    .findOutMoreButton {
      background-color: #fff;
      color: #7f7f7f;
      height: 30px;
      border-radius: 15px;
    } 
  }
  .nextDayDeliveryColumn {
    flex-grow: 4;
  }
}

// Small devices (landscape phones, less than 768px)
@media(max-width: 767.98px) {
  .col {
      margin-left: 0px;
      margin-right: 0px;
  }
}

@media(max-width: 991.98px) {
  #deliveryStrapline {
    margin-top: 32px;
  }
}