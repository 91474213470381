#CollectionsPage {
    .showMoreButton {
        text-align: center;
        button {
            background-color: $rkw-dark-blue;
            color: #fff;
            text-transform: uppercase;
            font-family: montserrat-bold;
            width: 150px;
            height: 30px;
            border-radius: 20px;
            font-size: .9rem;
            border: none;
        }
    }
}