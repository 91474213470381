/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

// Large devices (desktops, 992px and up)

#mobileTabletHeader {
  padding-bottom: 10px;
  position: fixed;
  background-color: white;
  top: 0;
  z-index: 1000;
  max-height: 100%;
  .scrollForDropdown {
    overflow: scroll !important;
  }
  #firstCol {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    padding-left: 0;
    img {
      max-width: 100px;
    }
    .accountRegistrationIcon {
      max-width: 28px;
      margin-bottom: 4px;
    }
    .userIcon {
      max-width: 25px;
      margin-bottom: 4px;
    }
    .phoneIcon {
      max-width: 23px;
      margin-bottom: 4px;
    }
    .basketIcon {
      max-width: 22px;
      margin-bottom: 4px;
    }
    .fa-bars {
      color: rgba(0, 0, 0, 0.5);
    }
    .shopByBrandLink {
      background: linear-gradient(#063d86, $rkw-dark-blue);
      margin-bottom: 3px;
      color: #fff;
    }
    .navbar-toggler {
      border: none;
      outline: none;
      padding: 0;
      height: 30px;
      width: 30px;
    }
    .navbar-light .navbar-toggler-icon {
      background-image: none;
    }
    .burgerIconSurrounding {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      position: relative;
      height: 30px;
      width: 30px;
      .burgerIcon {
        width: 26px;
        height: 3px;
        background-color: #7f7f7f;
        border-radius: 2px;
        -webkit-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
      }
      .burgerIcon::before,
      .burgerIcon::after {
        content: '';
        position: absolute;
        width: 26px;
        height: 3px;
        background-color: #7f7f7f;
        border-radius: 2px;
        -webkit-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
      }
      .burgerIcon::before {
        -webkit-transform: translateY(-8px);
            -ms-transform: translateY(-8px);
                transform: translateY(-8px);
      }
      .burgerIcon::after {
        -webkit-transform: translate(-13px, -22px);
            -ms-transform: translate(-13px, -22px);
                transform: translate(-13px, -22px);
      }
    }
    .openBurgerIcon {
      .burgerIcon {
        background: transparent;
        -webkit-transform: translateX(-50px);
            -ms-transform: translateX(-50px);
                transform: translateX(-50px);
      }
      .burgerIcon::before {
        -webkit-transform: translate(50px, 0px) rotate(45deg);
            -ms-transform: translate(50px, 0px) rotate(45deg);
                transform: translate(50px, 0px) rotate(45deg);
      }
      .burgerIcon::after {
        -webkit-transform: translate(37px, -30px) rotate(-45deg);
            -ms-transform: translate(37px, -30px) rotate(-45deg);
                transform: translate(37px, -30px) rotate(-45deg);
      }
    }
    .burgerIconCol {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
              width: 38px;
              padding: 0;
      
    }
  }
  .input-group {
    width: 100%;
    height: 40px;
    font-size: 1rem;
    border-radius: 20px;
    z-index: 10;
    margin: auto;
    .searchInput {
      border: 1px solid #ced4da;
      width: calc(100% - 50px);
      border-right: none;
      padding: 0 10px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    .searchInput:focus {
      border: 1px solid #ced4da;
      border-right: none;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
  }
  .input-group-append {
    margin: 0;
  }
  .searchButton {
    width: 50px;
    max-width: 400px;
    box-shadow: none;
    background-color: #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 1px solid #ced4da;
    cursor: pointer;
    color: #ced4da;
  }
  .searchButton:hover {
    color: #6c757d;
  }
  .input-group:hover,
  .input-group:focus {
    -webkit-box-shadow: 0 0 9px #bbb;
            box-shadow: 0 0 9px #bbb;
  }
  
  .svg-inline--fa.fa-w-16 {
    color: #ced4da;
    cursor: pointer;
  }
  .svg-inline--fa.fa-w-16:hover {
    color: #6c757d;
  }
  .btn-outline-secondary {
    border: 1px solid #ced4da;
    border-left: none;
    background-color: #fff;
  }
  #thirdCol {
    margin-top: auto;
    margin-bottom: auto;
    text-align: right;
    font-size: 16px;
    .fa-user {
      color: rgba(0, 0, 0, 0.5);
    }
    .fa-shopping-basket {
      color: rgba(0, 0, 0, 0.5);
    }
    .mobileIconsRow {
      .mobileIconsCol {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
                align-items: flex-end;
-webkit-box-pack: end;
-ms-flex-pack: end;
        justify-content: flex-end;
      }
      .mobileHeaderIcon {
        font-size: 20px;
        margin-bottom: 5px;
      }
      .logoutButton,
      .mobileHeaderLink {
        display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
            align-items: center;
            color: #000;
      }
      .burgerIconLogo,
      .loginTextMobile,
      .logoutTextMobile,
      .accoutTextMobile,
      .basketTextMobile,
      .basketTextMobile {
        font-size: .55rem;
        width: 38px;
      }
    }
  }
  #firstRow {
    margin-bottom: 10px;
  }
  .input-group-append {
    .searchButton {
      min-width: 40px;
    }
    .searchButtonDisabled {
      pointer-events: disabled;
    }
    .btn-outline-secondary {
      background-color: #fff;
      color: #ced4da;
      border-color: #ced4da;
      border-left: none;
    }
    .btn-outline-secondary:hover {
      color: #6c757d;
    }
  }
  .secondColPageNavs {
    background-color: #EDEFF1;
    margin-bottom: .5rem;
    a {
      color: $rkw-dark-blue;
      .secondRowIcon1,
      .secondRowIcon2 {
        color: $rkw-dark-blue;
        font-size: .9rem;
        margin-right: 5px;
      }
    }
  }
}

#desktopHeader {
  display: none;
  position: fixed;
  background-color: white;
  top: 0;
  z-index: 1001;
  .row {
    transition: 0.17s cubic-bezier(0, 0, 0.43, 1.06);
    -o-transition: 0.17s cubic-bezier(0, 0, 0.43, 1.06);
    -moz-transition: 0.17s cubic-bezier(0, 0, 0.43, 1.06);
    -webkit-transition: 0.17s cubic-bezier(0, 0, 0.43, 1.06);
  }
  img {
    max-width: 250px;
    height: auto;
  }
  #firstCol {
    text-align: center;
  }
  #secondCol {
    margin-top: auto;
    margin-bottom: auto;
    -webkit-box-shadow: none;
            box-shadow: none;
    outline: none;
    -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
    transition: -webkit-box-shadow 0.3s ease-in-out;
    -o-transition: box-shadow 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
    .input-group {
      width: 100%;
      height: 40px;
      font-size: 1rem;
      border-radius: 20px;
      z-index: 10;
      margin: auto;
      .searchInput {
        border: 1px solid #ced4da;
        width: calc(100% - 50px);
        border-right: none;
        padding: 0 10px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      .searchInput:focus {
        border: 1px solid #ced4da;
        border-right: none;
        -webkit-box-shadow: none;
                box-shadow: none;
      }
    }
    .formRow {
      padding: 10px 0;
    }
    .secondColPageNavs {
      background-color: #EDEFF1;
      a {
        color: $rkw-dark-blue;
        .secondRowIcon1,
        .secondRowIcon2 {
          font-size: .9rem;
          margin-right: 5px;
        }
      }
    }
    .pageNavsDivider {
      height: 34px;
      width: 2px;
      border-radius: 50%;
      background-color: #cbcfd2;
    }
    .input-group-append {
      margin: 0;
    }
    .searchButton {
      width: 50px;
      max-width: 400px;
      box-shadow: none;
      background-color: #fff;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border: 1px solid #ced4da;
      cursor: pointer;
      color: #ced4da;
    }
    .searchButton:hover {
      color: #6c757d;
    }
    .input-group:hover,
    .input-group:focus {
      -webkit-box-shadow: 0 0 9px #bbb;
              box-shadow: 0 0 9px #bbb;
    }
    
    .svg-inline--fa.fa-w-16 {
      font-size: 1.2rem;
    }
    .btn-outline-secondary {
      border: 1px solid #ced4da;
      border-left: none;
      background-color: #fff;
    }
  }

  .mainLogo {
    height: 52px;
  }
  #thirdCol {
    img {
      width: 18px;
      margin-right: 5px;
    }
    .accountRegistrationIcon {
      width: 21px;
    }
  }
  .secondRow {
    font-size: .85rem;
    font-weight: 600;
    a {
      color: #7f7f7f;
    }
  }
  .basketButton {
    background-color: $rkw-dark-blue;
    color: #fff;
    border: none;
    padding: 5px 18px;
    font-size: .7rem;
    .basketIcon {
      font-size: .9rem;
    }
  }
  .secondRowDivider {
    margin: 0;
    width: 100%;
    height: 4px;
    background-color: $rkw-light-blue;
  }
}
#fourthCol {
  bottom: 2px;
  .header-phone-number {
    float: right;
    color: #7f7f7f;
    font-size: 1.26em;
    a {
      color: #7f7f7f;
    }
  }
}

.desktopImage {
  transition: 0.17s cubic-bezier(0, 0, 0.43, 1.06);
  -o-transition: 0.17s cubic-bezier(0, 0, 0.43, 1.06);
  -moz-transition: 0.17s cubic-bezier(0, 0, 0.43, 1.06);
  -webkit-transition: 0.17s cubic-bezier(0, 0, 0.43, 1.06);
  max-width: 120px !important;
}

.mobileScroll {
  -webkit-box-shadow: 1px 1px 6px #4a4a4f;
          box-shadow: 1px 1px 6px #4a4a4f;
}

.showTelephoneNumber {
  display: none;
}

.formRowScroll {
  padding: 0 !important;
}

.basketTotal {
  position: absolute;
  top: -2px;
  right: calc(6.7vw + 58px);
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 0.8em;
  padding-left: 3px;
  padding-right: 3px;
  line-height: 12px;
  text-align: center;
  -webkit-box-shadow: 2px 2px 9px #000;
          box-shadow: 2px 2px 9px #000;
}

.basketTotalMobile {
  position: absolute;
  top: -4px;
  right: 2px;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 0.7em;
  padding-left: 3px;
  padding-right: 3px;
  line-height: 12px;
  text-align: center;
  -webkit-box-shadow: 2px 2px 9px #000;
          box-shadow: 2px 2px 9px #000;
}

// extra small devices (small mobile)
@media only screen and (max-width: 386.98px) {
  #thirdCol {
    padding-right: 1vw !important;
    padding-left: 1vw !important;
  }
}

// Large devices (desktops, 992px and up)
@media only screen and  (min-width: 991.98px) {
  #mobileTabletHeader {
    display: none;
  }
  #desktopHeader {
    display: block;
    img {
      max-width: 145px;
    }
  }
  #desktopHeader #thirdCol a {
      color: rgba(0, 0, 0, 0.5);
  }
}

  
