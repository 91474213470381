#NewsRoom {
    margin-top: 3rem;
    .newsRoomTextSurrounding {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 4px solid $rkw-light-blue;
        p {
            font-family: montserrat-bold;
            color: $rkw-dark-blue;
            margin-bottom: 0;
        }
    }
    a {
        text-decoration: none;
    }
}