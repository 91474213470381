$rkw-light-blue: #15a2d5;
$rkw-dark-blue: #032c62;

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: visible;
}

body {
  font-family: montserrat-regular;
  font-size: 12px;
  .App {
    padding-bottom: 280px;
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
  }
  #usefulInformation #sideNavigationBar ul li a {
    color: #636363;
  }
  #usefulInformation #sideNavigationBar ul li:first-child {
    border-bottom: 1px solid #636363;
    text-decoration: none;
    margin-bottom: 10px;
  }

  .mainContent {
    position: relative;
    padding-top: 0;
    padding-bottom: 100px;
    .navbarDivider {
      position: absolute;
      top: 56px;
      height: 4px;
      background-color: #ccc;
      width: 100%;
      left: 0;
    }
  }

  #sideNavigationBar {
    .staticPagesList::before {
      content: '>';
      font-size: 1.2em;
    }
  }

  .pagesLinks {
    font-size: 1em;
    margin-bottom: 20px;
    a {
      color: #000;
    }
  }
  
  .pageLinks:hover {
    color: #4a4a4f !important;
    text-decoration: none !important;
  }

  .static-page-active {
    color: #000 !important;
    font-weight: bold;
  }

  .blueButton{
    background-color: #032c62;
    color: #fff;
    border: none;
    padding: 5px 18px;
    font-size: .7rem;
  }

  .btn-primary {
    background-color: #539ec5;
    border: none;
    box-shadow: 1px 1px 6px #4a4a4f;
    outline: none;
    transition: background-color .1s ease-in, transform .1s ease-in, box-shadow .1s ease-in;
  }
  .btn-primary:hover {
    background-color: #3e7e9e;
  }
  .btn-primary:focus {
    box-shadow: 1px 1px 6px #4a4a4f;
  }
  .btn-primary:active {
    box-shadow: .5px .5px 3px #4a4a4f;
    transform: scale(0.98);
    background-color: #3e7e9e;
  }

  .btn-primary:not(:disabled):not(.disabled):active, 
  .btn-primary:not(:disabled):not(.disabled).active, 
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #3e7e9e;
  }

  .backDrop {
    display: none;
  }

  .backDropVisible {
    display: block !important;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    cursor: pointer;
  }

  #resetPwd {
    height: 61vh;
    .passwordsMustMatch {
      display: none;
    }
    .passwordsTooSimilar {
      display: none;
    }
    .resetPasswordErrorMessage {
      display: none;
    }
    .passwordsMustMatchNotify {
      display: block;
      color: red;
    }
    .passwordsTooSimilarNotify {
      display: block;
      color: red;
    }
    .resetPasswordErrorMessageNotify {
      display: block;
      color: red;
    }
  }

  .homePageHeading {
    color: $rkw-dark-blue;
    text-transform: uppercase;
    font-family: montserrat-bold;
    text-align: center;
    margin: 5px auto 1.5rem;
  } 

  @media only screen and (max-width: 767.98px) {
    .App {
      padding-bottom: 0px;
    }
  }
  @media only screen and (min-width: 992px) {
    .backDrop {
      display: none;
    }
    .mainContent {
      padding-top: 60px;
    }
  }
}
