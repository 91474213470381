/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

#over25YearsContainer {
    .textHeading {
        font-size: 10vw;
        color: #1e406d;
        #text25Years {
            color:#539ec5;
        }
    }
    .textHeadingMuted {
        font-size: 4vw;
        color:#539ec5;
        font-weight: 700;
    }
}

@media (min-width: 500px) {
    #over25YearsContainer {
        .textHeading {
            font-size: 50px;
        }
        .textHeadingMuted {
            font-size: 16px;
        }
    }
}