#exclusivelyLandingPage {

  margin-bottom: 150px;
  
  p {
    font-size: 1rem;
  }
  .vl {
    border-left: 3px solid silver;
    height: 125px;
  }
  .floor {
    margin-bottom: 7%;
  }
  .tower-floor h3 {
    color: black;
  }
  .tower-floor h3:hover {
    color: rgb(7, 27, 36);
  }
  .exclusivelyLandingPagePitchText {
    width: 90%;
    max-width: 800px;
    hr {
      margin-top: 1vw !important;
      background-color: black;
      height: 1px;
    }
    .heading {
      background: -webkit-linear-gradient(-45deg, #00aad6, #006e9b, #003764);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: system-ui;
      text-align: center;
      padding-bottom: 3px;
    }
    .heading-2 {
      background: white;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: system-ui;
      text-align: center;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      
    }
    .hall{
      padding: 5px;
      background-color: red;
    }
    .bottom-buffer{
      margin-bottom: 60px;
    }
  }
  .lineBreak {
    width: 90%;
    max-width: 200px;
    height: 2px;
    background-color: #4c93b7;
    border-radius: 50%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .towerStand {
    width: 90%;
    max-width: 800px;
    .logo {
      height: 3vw;
      margin: 0vw 1vw 0vw 1vw;
    }
    @media only screen and (min-width: 280px) and (max-width: 1020px) {
      .logo {
        height: 8vh;
        margin-top: 2vw;
      }
    }
  }
  .rkwStand {
    width: 90%;
    max-width: 800px;
    .logo {
      height: 6vw;
      margin: 0vw 1vw 0vw 1vw;
    }
    @media only screen and (min-width: 280px) and (max-width: 1020px) {
      .logo {
        height: 20vw;
        margin-top: 2.8vw;
      }
    }
  }
  //here u am
  .brandgrid {
    width: 10vw;
    margin-bottom: 50px;
  }
  //emd
  .brandsLogo {
    margin: 0vw 2vw 0vw 2vw;
  }
  @media only screen and (min-width: 280px) and (max-width: 1366px) {
    .brandsLogo {
      width: 20vw;
      margin-top: 2.8vw;
    }
  }
  .towerStandNumber {
    background: linear-gradient(-45deg, #00aad6, #006e9b, #003764);
    color: white;
    font-weight: bold;
    text-align: center;
  }
  .footerFix {
    margin-top: 2vw;
    margin-bottom: 5rem;
  }
  .bannerContainer {
    margin-bottom: 5vw;
    .towerBanners {
      background: #1a1a1a;
      border-top: 10px solid #666666;
      border-bottom: 10px solid #666666;
      .image {
        width: 25vw;
        margin-bottom: 1vw;
      }
      p {
        text-align: justify;
        -ms-flex-preferred-size: 1;
        flex-basis: 1;
        color: silver;
        margin-right: 10vw;
      }
      .logo {
        width: 10vw;
      }

      @media only screen and (min-width: 1024px) {
        .logo {
          width: 16vw;
          margin-top: 2.5vw;
        }
      }
      @media only screen and (min-width: 280px) and (max-width: 1023px) {
        .logo {
          width: 24vw;
          margin-top: 3vw;
        }
      }
      .imageDiv {
        margin-left: 15vw;
      }
    }
    .rkwBanners {
      background: linear-gradient(45deg, #00aad6, #006e9b, #003764);
      border-top: 10px solid #00aad6;
      border-bottom: 10px solid #00aad6;
      .image {
        width: 25vw;
        margin-bottom: 1vw;
      }
      p {
        text-align: justify;
        -webkit-flex-basis: 1;
        flex-basis: 1;
        color: silver;
        margin-left: 10vw;
      }
      .logo {
        width: 10vw;
      }
      .imageDiv {
        margin-right: 15vw;
      }
      @media only screen and (min-width: 280px) and (max-width: 1020px) {
        .logo {
          width: 20vw;
          margin-top: 2.8vw;
        }
      }
    }
  }
}
