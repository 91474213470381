/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

.accordionButton {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 40px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    outline: none !important;
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    .accordionButtonSub {
        background-color: #999;
        color: #fff;
    }
}

.greyBackground {
    background: linear-gradient($rkw-light-blue, #2b74a0);
}

.chevronContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}

.activate {
    background-color: #777;
}

.rotate {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: -webkit-transform 0.2s ease;
    -o-transition: transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.accordionContent {
    overflow: hidden;
    -webkit-transition: max-height 0.2s ease;
    -o-transition: max-height 0.2s ease;
    transition: max-height 0.2s ease;
}

.accordionSubCategories {
    padding: 0;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    .subCategoryLink {
        color: #fff;
        .subCategoryItem {
            color: #fff;
            background: linear-gradient(#696969, #525252);
            list-style-type: none;
            padding: 5px;
            padding-left: 20px;
            margin-bottom: 3px;
        }
    }
}