/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

#login {
  padding: 3rem .5rem;
  .card {
    border: 0px;
    .card-body {
      padding: 0px;
      .tradeAccountText {
        text-align: center;
        text-transform: uppercase;
        font-size: 1.5rem;
        color: $rkw-dark-blue;
        font-weight: 500;
      }
      .loginForm {
        .formLabel {
          color: #aaa;
        }
        .forgottenPwd {
          color: black;
          text-decoration: underline;
          text-align: center;
          cursor: pointer
        }
      }
    }
  }

  .loginPageButton {
    background-color: $rkw-dark-blue;
    color: #fff;
    border: none;
    width: 100%;
    height: 40px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: .8rem;
  }

  .subHeading {
    color: #aaa;
    text-align: center;
    font-size: 1.2rem;
  }

  .col-md-6 {
    margin-left: auto;
    margin-right: auto;
  }

  #pwdWrapper {
    text-align: right;
    margin-bottom: 16px;
  }
  #showPassword {
    position: absolute;
    right: 0;
    margin-right: 10px;
    margin: 11px;
    color: #6d74a7;
    text-transform: uppercase;
    cursor: pointer;
  }

  .failedLogin {
    display: none;
  }

  .failedLoginActive {
    display: block;
    color: red;
  }
}
  