#Accordion {
    .accordionItem1 {
        border-top: 1px solid #585858;
    }
    .accordionItem {
        border-bottom: 1px solid #585858;
        .accordionHeading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 10px;
            .accordionTitle {
                font-size: 1.2rem;
                margin-bottom: 0;
            }
            .accordionControl {
                font-size: .6rem;
                transition: transform .15s ease-in;
                transform: rotate(45deg);
                font-size: .75rem;
            }
        }
        .accordionContent {
            margin: 10px 10px 30px;
            transition: all .15s ease-in;
            text-align: left;
            .descriptionItemno {
                color: grey;
            }
            .productSpecTable {
                border-collapse: collapse;
                font-size: .9rem;
                width: 100%;
                margin-bottom: 1.5rem;
                padding-left: 20px;
            }
            .productSpecTable2 {
                border-collapse: collapse;
                font-size: .9rem;
                width: 100%;
                tr {
                    width: 100%;
                    border-bottom: 1px solid #585858;
                    td {
                        width: 50%;
                        padding: 10px;
                    }
                    td:nth-child(2) {
                        border-left: 1px solid #585858;
                        text-align: center;
                    }
                }
                tr:nth-last-child(1) {
                    border-bottom: none;
                }
            }
            .downloadButtonLink {
                display: inline-block;
                width: 250px;
                height: unset;
                border: 1px solid grey;
                font-size: 15px;
                background-color: #fff;
                margin: 0 10px 10px 0;
                padding: 6px 0;
                text-align: center;
            }
        }
    }
}