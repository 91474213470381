#AccountRegistration {
    margin-top: 3rem;
    .accountRegistrationText {
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 4rem;
    }
    .accountRegistrationCard {
        position: relative;
        border: 3px solid $rkw-dark-blue;
        border-radius: 20px;
        height: 100%;
        .accountHeaderSurrounding {
            position: absolute;
            width: 100%;
            top: -24px;
            .accountHeader {
                font-family: montserrat-bold;
                width: 230px;
                margin: auto;
                background-color: #fff;
                color: $rkw-dark-blue;
                padding: 10px 5px;
                border: 3px solid $rkw-dark-blue;
                border-radius: 30px;
                font-size: .9rem;
            }
        }
        .callUsOn {
            font-size: 1.3rem;
        }
        .phoneNumber {
            line-height: 32px;
            a {
                font-family: montserrat-bold;
                color: $rkw-dark-blue;
                font-size: 1.6rem;
            }
        }
        .downloadPDF {
            padding: 11px 0;
            cursor: pointer;
            .downloadButton {
                background-color: #032c62;
                color: #fff;
                margin-top: 1.5rem;
                height: 36px;
                border: none;
                padding: 0;
                border-radius: 18px;
                .accountRegistrationLink {
                    color: #fff;
                    padding: 1rem 2rem;
                }
            }
        }
        .accountRegistrationButton {
            background-color: #032c62;
            color: #fff;
            margin-top: 1.5rem;
            height: 36px;
            border: none;
            padding: 0;
            border-radius: 18px;
            .accountRegistrationLink {
                color: #fff;
                padding: 1rem 2rem;
            }
        }
        .loginPageLink {
            color: $rkw-light-blue;
            .userIcon {
                width: 40px;
            }
        }
    }
}


@media only screen and (min-width: 500px) {
    .accountHeaderSurrounding {
        top: -29px !important;
        .accountHeader {
            width: 330px !important;
            font-size: 1.3rem !important;
        }
    }
}