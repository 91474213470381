@import url('https://fonts.googleapis.com/css?family=Exo:100,200,300,400,500,600,700');

#usefulInformation {
  font-size: 12px;
  text-align: start;
  padding-top: .1px;
  padding-bottom: 30px;
  .usefulInformationImage {
    .usefulInformationImageHeading {
      position: absolute;
      background-color: #032c62c2;
      height: 125%;
      color: #fff;
      z-index: 1;
      h2 {
        font-family: montserrat-bold;
        border-bottom: 3px solid $rkw-light-blue;
      }
      p {
        width: 80%;
        max-width: 500px;
        text-align: center;
      }
    }
    .usefulInformationImageRight {
      position: relative;
      .usefulInformationImageOverlay {
        position: absolute;
        background: transparent;
        height: 100%;
        width: 100%;
      }
    }
  }

  .pageLinkRow {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    margin: 3rem 0;
    background-color: #ccc;
    .pageLink {
      cursor: pointer;
      a {
        text-decoration: none;
      }
      .pageLinkImage {
        height: 45vw;
        max-height: 325px;
        background-size: cover !important;
        background-position: center top !important;
      }
      .pageLinkTitle {
        background-color: $rkw-dark-blue;
        color: #fff;
        text-align: center;
        padding: 10px;
        p {
          margin-bottom: 10px;
        }
        .aboutUsTitleDivider {
          margin: 0;
          height: 2px;
          background-color: $rkw-light-blue;
          transition: width .3s ease-in;
        }
      }
    }
  }

  h2 {
    font-size: 32px;
    line-height: 35px;
    text-align: center;
  }

  #siteTermsAndConditions {
    margin-top: 3rem;
    margin-bottom: 10em;
    .termsHeading {
      font-family: montserrat-bold;
      font-size: 1.3rem;
      text-align: center;
      margin-bottom: 2rem;
    }
    .termsSubheading {
      font-family: montserrat-bold;
    }
    .termsOrderedList {
      list-style-type: none;
      counter-reset: item;
      margin: 0;
      padding: 0;
      margin-bottom: 30px;
    }
    
    .termsOrderedList > li {
      display: table;
      counter-increment: item;
      margin-bottom: 1em;
    }
    
    .termsOrderedList > li:before {
      content: counters(item, ".") ". ";
      display: table-cell;
      padding-right: 0.9em;
      font-weight: 900; 
    }
    .termsOrderedListLetter {
      padding: 0;
    }
    .termsOrderedListLetter > li {
      padding-left: .5rem;
    }
    .termsOrderedListLetter > li::marker {
      font-weight: 900;
    }
    li .termsOrderedList > li {
      margin: 0;
    }
    
    li .termsOrderedList > li:before {
      content: counters(item, ".") " ";
    }
  }

  // Medium devices (mobiles, 576px and up)
  @media only screen and (min-width: 576px) {
    .pageLink {
      .pageLinkImage {
          height: 25vw !important;
      }
    }
  }


  // Medium devices (tablets, 768px and up)
  @media only screen and (min-width: 768px) {
    h2 {
      font-size: 32px;
    }
    .textContainer {
      -ms-flex-preferred-size: 80% !important;
          flex-basis: 80% !important;
    }
    .pageLink {
      .pageLinkImage {
          height: 17vw !important;
      }
    }
  }

  // Large devices (desktop, 992px and up)
  @media only screen and (min-width: 992px) {
    .usefulInformationImageHeading {
      position: static !important;
      background-color: $rkw-dark-blue !important;
      height: unset !important;
    }
    .usefulInformationImageOverlay {
      background: linear-gradient(90deg, $rkw-dark-blue, transparent, transparent, transparent) !important;
    }
    .pageLink {
      .pageLinkImage {
          height: 13vw !important;
      }
    }
  }
}