#WhatWeDo {
    margin-top: 3rem;
    .whatWeDoText {
        text-align: center;
        font-size: .9rem;
    }
    .whatWeDoSliderHeading {
        font-family: montserrat-bold;
        text-align: center;
        text-transform: uppercase;
        margin: 4rem 0 2rem;
        width: 100%;
        font-size: 1.3rem;
    }
    .whatWeDoItemImage {
        width: 100px;
    }
    .whatWeDoItemText {
        font-family: montserrat-bold;
        text-transform: uppercase;
        text-align: center;
    }
}