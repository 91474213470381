#collection {
    .moreCollections {
        a {
            color: #fff;
            width: 100%;
            height: 100%;
            button {
                background-color: #193865;
                width: 100%;
                height: 100%;
                border: none;
                padding: 30px 0;
                .moreCollectionsImage {
                    width: 200px;
                }
            }
        }
    }
}

