#returnsInformation {
    margin-top: 3rem;
    .returnsColumn {
        position: relative;
        margin-bottom: 3rem;
        .returnsColumnSurrounding {
            border: 4px solid $rkw-light-blue;
            border-radius: 20px;
            height: 100%;
            .returnsImageContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-color: #fff;
                top: -40px;
                left: calc(50% - 40px);
                width: 80px;
                height: 80px;
                border: 4px solid $rkw-light-blue;
                border-radius: 50px;
                .returnsImage {
                    height: 65%;
                }
            }
            .textContainer {
                padding: 40px 10px 20px;
                text-align: center;
                h4 {
                    font-family: montserrat-bold;
                    font-size: 1rem;
                }
                .muted {
                    font-family: montserrat-bold;
                    font-size: .8rem;
                }
                span, a {
                    font-size: 1rem;
                    font-weight: 700;
                    color: $rkw-dark-blue;
                }
            }
        } 
    }
}