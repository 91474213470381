#ProductDevelopment {
    margin-top: 3rem;
    .productDevelopmentRow {
        margin-top: 3rem;
        .productDevelopmentHeading {
            width: 80%;
            max-width: 400px;
            border-bottom: 4px solid $rkw-light-blue;
            h3 {
                font-family: montserrat-bold;
                text-transform: uppercase;
            }
        }
        p {
            font-size: .9rem;
        }
        .productDevelopmentNumber {
            font-family: montserrat-bold;
            color: $rkw-dark-blue;
        }
    }
}