.modal {
    .modal-title {
        text-transform: uppercase;
        text-align: center;
        margin-left: 25%;
        color: rgba(0, 0, 0, 0.5)
    }
    .modal-body {
        max-height: 50vh;
        overflow-y: scroll;
        .compareItem img {
            width: 100%;
        }
        .compareItem ul {
            text-align: left;
            li {
                list-style: none;
            }
        } 
    }
}