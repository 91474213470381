/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

#orderConfirmation {
    padding-top: 20px;
    padding-bottom: 100px;
    h1 {
        font-family: montserrat-bold;
    }
    .buttonContainer {
        width: 100%;
        button {
            background-color: $rkw-light-blue;
            border: none;
            border-radius: 10px;
            color: #fff;
            padding: 5px 40px;
            font-size: 0.9rem;
        }
    }
    .confirmationGreenTickContainer {
        width: 100%;
        .confirmationGreenTick {
            width: 100px;
        }
    }
}



