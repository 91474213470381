.PriceSlider {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    .slider {
        display: flex;
        height: 36px;
        width: 100%;
        .track {
            height: 3px;
            width: 100%;
            border-radius: 2px;
            align-self: center;
        }
    }
    .thumb {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        top: 17px;
        background-color: #142c62;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 6px #AAA;
    }
    .priceText {
        color: #666;
        font-weight: 600;
    }
    output {
        margin-top: 10px;
        width: 100%;
        text-align: center;
    }
}