#bannerCarousel {
    .bannerLinksRow {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        .bannerPageLinkCol {
            cursor: pointer;
            a {
                text-decoration: none;
            }
            .backgroundImage {
                height: 43vw;
            }
            .bannerPageLinkTitle {
                background-color: $rkw-dark-blue;
                color: #fff;
                text-align: center;
                padding: 5px;
                p {
                    margin-bottom: 5px;
                }
                .bannerPageLinkTitleDivider {
                    margin: 0;
                    height: 2px;
                    background-color: $rkw-light-blue;
                    transition: width 0.3s ease-in;
                }
            }
            .bannerPageLinkTitle1 {
                margin-bottom: 4px;
            }
        }
    }
    .bannerSlider {
        .bannerImage {
            width: 100%;
        }
        margin: 0;
        .bannerItem {
            padding: 3px;
            .bannerCard {
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: $rkw-dark-blue;
                height: 250px;
                a {
                    color: #fff;

                    p {
                        font-size: 1rem;
                        padding: 10px;
                    }
                }
            }
        }
        .slick-prev {
            left: 15px;
            z-index: 10;
        }
        .slick-next {
            right: 15px;
            z-index: 10;
        }
        .slick-prev:before {
            position: absolute;
            top: 2px;
            right: -15px;
            font-family: "slick";
            font-size: 50px;
            line-height: 50%;
            margin-left: -2%;
            opacity: 99.75;
            color: #fff;
            padding: 0;
            border-radius: 50%;
            -webkit-font-smoothing: antialiased;
        }
        .slick-next:before {
            position: absolute;
            top: 2px;
            left: -15px;
            font-family: "slick";
            font-size: 50px;
            line-height: 50%;
            margin-left: -2%;
            opacity: 99.75;
            color: #fff;
            padding: 0;
            border-radius: 50%;
            -webkit-font-smoothing: antialiased;
        }
    }
}

@media only screen and (min-width: 768px) {
    #bannerCarousel {
        .bannerPageLinkCol {
            .backgroundImage {
                height: 33vw !important;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    #bannerCarousel {
        .bannerPageLinkCol {
            .backgroundImage {
                height: calc(13.75vw - 41px) !important;
            }
        }
    }
    .bannerImage {
        width: 100%;
    }
}

.bannerImage {
    width: 100%;
}
