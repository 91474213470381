/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */


$not-in-stock-color: #d41a1a;
$in-stock-color: #25cc1b;
$limited-stock-color: #ffc136;
$slick-slide-color: #495057;
$basket-update-color: #69e21d;
$tab-content: #dee2e6;

#productContainer {
    padding-top: 20px;
    padding-bottom: 20px;
    .slick-track {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    .slick-prev {
        left: -18px;
    }
    .slick-next {
        right: -18px;
    }
    .slick-prev:before {
        content: "⟨";
    }
    .slick-next:before {
        content: "⟩";
    }
    .slick-next:before, .slick-prev:before {
        color: #4a4a4f;
    }

    
    
    .secondSlide {
        .slick-current {
            
            margin: 0;
            -webkit-transform: scale(1.1);
                -ms-transform: scale(1.1);
                    transform: scale(1.1);
        }
        .slick-slide {
            margin: 8px;
            width: 85px !important;
            border: 1px solid $slick-slide-color;
            img {
                margin: 10px;
            }
        }
    }
    a {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        color: black;
        height: 55px;
    }
    p {
        font-size: 14px;
    }
    .productDescriptionCol {
        // .brandProductName {
        //     font-weight: bold;
        //     color: grey;
        // }
        h3 {
            font-size: 1.6rem;
            font-family: montserrat-light;
        }
        .productName {
            color: #777777;
            font-family: montserrat-light;
        }
        .tradePrice {
            color: #777777;
            font-family: montserrat-light;
        }
        .itemNumber {
            color: #9b9b9b;
            font-family: montserrat-light;
        }
        .viewProductDescription {
            text-decoration: underline;
            cursor: pointer;
        }
        .productColour {
            width: 25px;
            height: 25px;
            border: 1px solid #000;
            border-radius: 50%;
        }
        .coloursHeading {
            color: #777777;
            font-weight: 1.3rem;
        }
        .productColourList {
            .itemVariantColourLink {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #4a4a4f;
                height: 40px;
                width: 110px;
                a {
                    color: #4a4a4f;
                }
                .itemVariantColour {
                    padding: 5px 12px;
                    border: 1px solid #4a4a4f;
                    border-radius: 50%;
                }
                .abbrColourVariant {
                    cursor: pointer;
                }
            }
            .itemVariantColourLinkActive {
                border: 1px solid #000;
                color: #000;
                .itemVariantColour {
                    border: 1px solid #000;
                    position: relative;
                }
                .itemVariantColour::before {
                    position: absolute;
                    content: "\2713";
                    color: black;
                    font-size: 1.3rem;
                    left: 4px;
                    top: -2px;
                }
                a {
                    color: #000;
                }
            }
        }
        .productListDivider {
            height: 1px;
            width: 100%;
            background-color: #4a4a4f;
            border: none;
        }
    }
    .coloursCircles {
        font-size: 1rem;
        .fa-circle {
            margin-left: 15px;
        }
    }
    form {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        input[name=quantity] {
            width: 90px;
            margin-right: 10px;
        }
        .btn {
            width: 200px;
            border-radius: 0px;
            -webkit-transition: -webkit-transform 0.1s ease-in;
            transition: -webkit-transform 0.1s ease-in;
            -o-transition: transform 0.1s ease-in;
            transition: transform 0.1s ease-in;
            transition: transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
        }
        .btn:active {
            -webkit-transform: scale(0.98);
                -ms-transform: scale(0.98);
                    transform: scale(0.98);
        }
    }
    #tabsRow {
        font-size: 14px;
        text-align: justify;
        div {
            padding: 10px;
            margin-bottom: 10px;
        }
    }
    .nav-tabs {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        .nav-link {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            border-radius: 0px;
        }
    }
    .tab-content {
        border-left: 1px solid $tab-content;
        border-right: 1px solid $tab-content;
        border-bottom: 1px solid $tab-content;
        height: 200px;
        overflow-y: scroll;
        
        div {
            padding: 30px;
        }
        .downloadImageList > li {
            list-style-type: none;
        }
    }
    img:active {
        cursor: -webkit-grabbing;
        cursor: grabbing;
    }
    .stockStatus {
        margin-bottom: 1rem;
        #inStock {
            color: $in-stock-color;
            font-size: 0.9rem;
            margin-bottom: 1rem;
        }
        #notInStock {
            color: $not-in-stock-color;
            font-size: 0.9rem;
            margin-bottom: 1rem;
        }
        #limitedStock {
            color: $limited-stock-color;
            font-size: 0.9rem;
            margin-bottom: 1rem;
        }
    }

    #sliderWrapper {
        .sliderControlImgMain {
            -webkit-transition: -webkit-transform 0.2s ease-in;
            transition: -webkit-transform 0.2s ease-in;
            -o-transition: transform 0.2s ease-in;
            transition: transform 0.2s ease-in;
            transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
        }
        .mainProductImage {
            position: relative;
            .enlargeImageIcon {
                position: absolute;
                right: 3px;
                bottom: 3px;
                width: 14px;
            }
        }
        .mainProductImage:hover {
            cursor: -webkit-zoom-in;
            cursor: zoom-in;
        }
        .mainProductVideo {
            width: 100%;
            height: 100%;
            .productVideo {
                width: 96% !important;
                height: 96% !important;
                margin: auto;
            }
            .rh5v-Volume_icon {
                width: 28px;
                height: 28px;
            }
            .rh5v-DefaultPlayer_component {
                background-color: transparent;
            }
        }
        .sliderMainImage {
            max-height: 400px;
            max-width: 100%;
        }
        .sliderControlImg {
            max-width: 100%;
            max-height: 400px;
            margin: auto;
        }   
    }

    .sliderControlImgListBlueBorder {
        border: 1px solid blue;
    }

    .basketUpdater {
        margin-top: 18px;
        background-color: $basket-update-color;
        color: white;
        border-radius: 4px;
        left: 0;
        top: 214px;
        width: 100%;
        height: 43px;
        font-size: 1.5em;
        padding: 13px 35px;
        line-height: 12px;
        text-align: center;
        z-index: -100000;
        -webkit-animation: updateBasket 0.2s forwards 1;
                animation: updateBasket 0.2s forwards 1;
    }

    

    .collectionSlider {
        .slick-slide {
            padding-left: 10px;
            padding-right: 10px;
        }
        
        a {
            height: 100%;
        }
    }
    .collectionRow {
        .slick-next:before, .slick-prev:before {
            color: #4a4a4f;
            font-size: 2rem;
        }
        a {
            text-decoration: none;
        }
        .card {
            border: none;
            .collectionImageSurrounding {
                height: calc(37vw - 36px);
                width: 80%;
                margin: auto;
                img {
                    height: 100%;
                }
            }
            .cardTitleSurrounding {
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 60px;
                .card-title {
                    font-size: .8rem;
                }
            }
            .collectionItemNumber {
                border-bottom: 1px solid $rkw-light-blue;
                width: 80%;
                margin: auto;
                font-weight: 800;
            }
            .priceSurrounding {
                padding-top: 5px;
                padding-bottom: 5px;
                .collectionSSP,
                .collectionRRP {
                    font-weight: 800;
                    line-height: 10px;
                }
            }
        }
    }
    .alreadyInBasketModal,
    .outOfStockModal {
        visibility: hidden;
    }

    .downloadsAvailable {
        width: 33.3333%;
    }

    .downloadsNotAvailable {
        width: 33.3333%;
    }

    
    @media only screen and (max-width:575.98px) {
        .sliderControlImgList {
            max-height: 15vw !important;
        }
    }
    // Small(anything up to 767px)
    @media only screen and (max-width:767.98px) {
        .sliderControlImgList {
            max-height: 94px !important;
        }
    }
    // Medium(anything up to 991px)
    @media only screen and (max-width:991.98px) {
        .sliderControlImgList {
            max-height: 52px !important;
        }
    }
    // Large(anything up to 1199px)
    @media only screen and (max-width:1199.98px) {
        .sliderControlImgList {
            max-height: 72px !important;
        }
    }



    @media only screen and (min-width:575px) {
        .basketUpdater {
            top: 153px;
        }
        .collectionImageSurrounding {
            height: 175px !important;
        }
        .cardTitleSurrounding {
            min-height: 45px !important;
        }
    }

    @media only screen and (min-width:768px) {
        .collectionImageSurrounding {
            height: 165px !important;
        }
    }

    @media only screen and (min-width:992px) {
        // .basketUpdater {
        //     margin-top: 18px;
        //     background-color: #69e21d;
        //     color: white;
        //     border-radius: 4px;
        //     left: 236px;
        //     top: 0px;
        //     font-size: 1.5em;
        //     padding: 13px 35px;
        //     line-height: 12px;
        //     text-align: center;
        //     z-index: -100000;
        //     -webkit-animation: updateBasket 0.5s forwards 1;
        //             animation: updateBasket 0.5s forwards 1;
        // }
    }


    // Extra Large(anything 1200px & up)
    @media only screen and (min-width:1200px) {
        .sliderControlImgList {
            max-height: 85px !important;
        }
        .secondSlide {
            .slick-slide {
                width: 85px !important;
            }
        }
    }

    @-webkit-keyframes updateBasket {
        0% {
            z-index: 100;
            -webkit-box-shadow: none;
                    box-shadow: none;
            opacity: 0;
        }
        100% {
            z-index: 998;
            -webkit-box-shadow: 2px 2px 9px #69e21d;
                    box-shadow: 2px 2px 9px #69e21d;
            opacity: 1;
        }
    }

    @keyframes updateBasket {
        0% {
            z-index: 100;
            -webkit-box-shadow: none;
                    box-shadow: none;
            opacity: 0;
        }
        100% {
            z-index: 998;
            -webkit-box-shadow: 2px 2px 9px #69e21d;
                    box-shadow: 2px 2px 9px #69e21d;
            opacity: 1;
        }
    }
}