/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.planForGrowthForm {
    width: 90%;
    max-width: 700px;
    padding: 10px;
    .form-control {
        background-color: #ddd;
        border: none;
    }
    .planForGrowthSubmitButton {
        background-color: #263642;
        color: #fff;
        font-weight: bold;
        width: 90%;
        max-width: 200px;
        -webkit-box-shadow: 2px 2px 5px #4f4f4a;
                box-shadow: 2px 2px 5px #4f4f4a;
        -webkit-transition: -webkit-transform .07s ease-in, -webkit-box-shadow .07s ease-in;
        transition: -webkit-transform .07s ease-in, -webkit-box-shadow .07s ease-in;
        -o-transition: transform .07s ease-in, box-shadow .07s ease-in;
        transition: transform .07s ease-in, box-shadow .07s ease-in;
        transition: transform .07s ease-in, box-shadow .07s ease-in, -webkit-transform .07s ease-in, -webkit-box-shadow .07s ease-in;
    }
    .planForGrowthSubmitButton:active {
        -webkit-transform: scale(.98);
            -ms-transform: scale(.98);
                transform: scale(.98);
        -webkit-box-shadow: none;
                box-shadow: none;
    }
}