#customerCare {
    margin-top: 3rem;
    .customerCareColumn {
        position: relative;
        margin-bottom: 5rem;
        text-align: center;
        .customerCareHeadingSurrounding {
            position: absolute;
            display: flex;
            justify-content: center;
            width: 100%;
            left: 0;
            top: -19px;
            .customerCareHeading {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                width: 230px;
                height: 40px;
                font-family: montserrat-bold;
                text-transform: uppercase;
                font-size: .8rem;
                border-radius: 20px;
                border: 3px solid $rkw-light-blue;
            }
        }
        .customerCareInformation {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 3rem 1rem 1rem;
            height: 100%;
            border-radius: 20px;
            border: 3px solid $rkw-light-blue;
            .customerCareContact {
                margin-bottom: 1rem;
                .customerCareSpan a {
                    color: #000;
                    font-family: montserrat-bold;
                    font-size: .9rem;
                }
                .customerCareIcon {
                    height: 15px;
                    margin-right: 5px;
                }
            }
            
            .extendYourWarrantButton {
                background-color: $rkw-dark-blue;
                padding: 10px;
                border-radius: 20px;
                border: none;
                a {
                    color: #fff;
                    padding: 12px 18px;
                }
            }
            .TowerHousewaresButton {
                background-color: #000;
                padding: 10px;
                border-radius: 20px;
                border: none;
                a {
                    color: #fff;
                    padding: 12px 18px;
                }
            }
        }
    }
    .extendYourWarrantyColumn {
        .customerCareHeading {
            width: 295px !important;
            border: 3px solid $rkw-dark-blue !important;
        }
        .customerCareInformation {
            border: 3px solid $rkw-dark-blue !important;
        }
    }
    .consumerWebsiteColumn {
        .customerCareHeading {
            width: 295px !important;
            border: 3px solid #000 !important;
        }
        .customerCareInformation {
            border: 3px solid #000 !important;
        }
    }
    .towerLogo {
        width: 200px;
    }
}


@media only screen and (min-width: 576px) {
    .customerCareHeading {
        font-size: 1rem !important;
    }
}