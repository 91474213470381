/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

#planForGrowthConfirmation {
    padding-top: 20px;
    padding-bottom: 100px;
}

.buttonContainer {
    width: 100%;
}

.confirmationGreenTickContainer {
    width: 100%;
    .confirmationGreenTick {
        width: 100px;
    }
}