.cookiesPolicyContainer {
    position: fixed;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.87);
    color: #fff;
    z-index: 1000;
    .cookiesPolicyText {
        font-size: .9rem;
        margin: 0;
    }
    .cookiesPolicyButton {
        border-radius: 15px;
        height: 35px;
        font-size: .95rem;
    }
}