/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

.modal {
    margin-top: 10vh;
    .bodyStyle {
        font-size: 1.1rem;
        overflow-y: none !important;
        overflow: hidden !important;
        padding: 25px 30px;
        text-align: center;
        .itemInBasketImage {
            width: 150px;
        }
    }
    
    .noBorder {
        border: none;
    }
    
    .invisibleButton {
        visibility: hidden;
    }
    
    .modal-content {
        border-radius: 20px;
    }
}