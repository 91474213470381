#footerSurroundingContainer {
  width: 100%;
  bottom: 0;
  .footerSurrounding {
    position: relative;
  }
  .footerOverlay {
    background: linear-gradient(45deg, $rkw-light-blue, $rkw-dark-blue);
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  
    .spiralImage03 {
      position: absolute;
      height: 257px;
      top: 0;
      right: 0;
    }
    .spiralImage01 {
      position: absolute;
      height: 255px;
      bottom: 0;
      left: 0;
    }
  }
  #spacerContainer {
    border-bottom: 2px solid $rkw-dark-blue;
  }

  .footerContainer {
    .footerContactList {
      font-size: 1.2rem;
      span {
        color: #fff;
      }
      a {
        color: #fff;
        font-weight: 800;
      }
    }
    .footerContactBullet {
      color: $rkw-light-blue;
    }
    .footerList {
      font-size: 0.85rem;
      color: #fff;
      max-width: 900px;
      margin: auto;
      a {
        color: #fff;
      }
    }
    .ourBadges {
      width: 90px;
    }
    .awardBadge{
      height: 70px;
      margin-left: 50px;
      margin-right: 50px;
    }
  }

  .modernSlaveryColumn {
    h5 {
      text-align: center;
      color: #fff;
      font-size: 0.9rem;
    }
    .modernSlaveryPolicyContainer {
      a {
        color: #fff;
        opacity: 0.4;
        -webkit-transition: opacity 0.17s ease-in;
        -o-transition: opacity 0.17s ease-in;
        transition: opacity 0.17s ease-in;
      }
      a:hover {
        opacity: 0.9;
      }
    }
  }

  #copyrightRKW {
    background-color: $rkw-dark-blue;
    p {
      color: #fff;
      margin-bottom: 0px;
      text-align: center;
    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991.98px) {
      margin-right: 0px;
      margin-left: 0px;
    }
  }
}

@media only screen and (min-width: 767.98px) {
  #footerSurroundingContainer {
    position: absolute;
  }
}
