/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

$basket-updater: #69e21d;

#searchPageContainer {
  padding-top: 20px;
  padding-bottom: 40px;
  .headingSurrounding {
    border-top: 2px solid $rkw-light-blue;
    border-bottom: 2px solid $rkw-light-blue;
    h2 {
      font-weight: 900;
    }
  }
  .searchPageSurrounding {
    margin-bottom: 50px;

    a {
      color: black;
    }
    .productsSortByFiltersCol {
      display: flex;
      align-items: center;
      .productsSortByFiltersText,
      .productsSortByFiltersSelectItem,
      .productsPerPageFiltersSelectItem {
        font-size: 0.8rem;
      }
      input[type="checkbox"] {
        position: relative;
        width: 40px;
        height: 20px;
        appearance: none;
        background-color: #c6c6c6;
        outline: none;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
      }
      input:checked[type="checkbox"] {
        background-color: #62cd20;
      }
      input[type="checkbox"]:before {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.5rem;
        font-family: montserrat-bold;
        content: "OFF";
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        top: 0;
        left: 0;
        background-color: #fff;
        transform: scale(1.1);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
        transition: all 0.3s;
      }
      input:checked[type="checkbox"]:before {
        left: 20px;
        content: "ON";
      }
      select {
        outline: none;
        option {
          outline: none;
        }
      }
    }
  }
  .pageLinks {
    font-size: 1.3em;
  }
  #showLess {
    color: red;
    cursor: pointer;
    display: none;
  }

  #firstRow {
    margin: 0 1rem 1rem;
    #buttonContainer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      .btn {
        float: left;
        color: white;
        min-width: 85px;
      }
    }
    .form-group {
      height: 100%;
      float: right;
    }
    select {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  #secondRow {
    .itemsPerPageHeader {
      font-size: 0.89rem;
    }

    .itemsPerPage {
      .itemsPerPageSelect {
        width: 50px;
        height: 25px;
      }
    }

    .resetAllFilters:hover {
      text-decoration: underline;
      cursor: pointer;
    }
    #filters {
      margin-bottom: 30px;
      background-color: #ececec;
      padding: 10px;
      .filterByText {
        border-bottom: 2px solid #aaa;
        h2 {
          font-size: 1.7rem;
          font-weight: 800;
        }
      }
      .filterWrapper {
        margin-bottom: 10px;
        .plusMinusIcon {
          border-bottom: none;
          color: #666;
        }
        div {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
        h3 {
          font-size: 14px;
          font-weight: bold;
          padding: 11px 0;
          margin: 0;
          font-size: 1rem;
        }
        #priceFilter {
          overflow-y: hidden;
          height: 70px;
        }
        .filterLoading {
          border-bottom: none;
          width: 100%;
          .col {
            border-bottom: none;
            .Loader {
              border-bottom: none;
              font-size: 4px;
            }
          }
        }
        .filtersList {
          position: relative;
          list-style: none;
          padding: 0;
          overflow-y: auto;
          width: 100%;
          transition: max-height 0.2s ease-in, padding-top 0.2s ease-in;
          margin-bottom: 0;
          .slider {
            -webkit-appearance: none;
            background-color: #000;
            width: 100%;
            height: 2px;
            border-radius: 1px;
            border-radius: 5px;
            outline: none;
            &::-webkit-slider-thumb {
              background-color: #fff;
              -webkit-appearance: none;
              appearance: none;
              height: 12px;
              width: 12px;
              border: 2px solid #000;
              text-align: center;
            }
          }
          h3 {
            font-size: 14px;
            font-weight: bold;
            display: block;
          }
          input {
            -webkit-appearance: none;
            outline: none !important;
            border: none !important;
          }

          li {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: space-between;

            color: #666;
            .filterItemName {
              font-size: 0.64rem;
              width: 82%;
            }
            span {
              padding-left: 10px;
              pointer-events: none;
              position: absolute;
              z-index: 1;
            }

            .inputCircle {
              background-color: #fff;
              min-width: 10px;
              min-height: 12px;
              border: 1px solid #000;
              border-radius: 50%;
              right: 15px;
            }
            input[name="offer"],
            input[name="category"],
            input[name="brand"],
            input[name="collection"],
            input[name="colour"] {
              // background-color: #fff;
              position: relative;
              appearance: none;
              width: 100%;
              box-shadow: none;
              outline: none;
              // border: 1px solid red;
            }
            input:checked[name="offer"]:before,
            input:checked[name="category"]:before,
            input:checked[name="brand"]:before,
            input:checked[name="collection"]::before {
              position: absolute;
              top: calc(50% - 11px);
              font-size: 0.9rem;
              right: 4px;
              content: "✔";
              z-index: 1;
            }
          }
          input[name="colour"] {
            position: relative;
            appearance: none;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            outline: none;
          }
          input:checked[name="colour"]:before {
            position: absolute;
            top: -1px;
            left: 4px;
            font-size: 1.2rem;
            content: "✔";
          }
          @media (max-width: 1200px) {
            input:checked[name="colour"]:before {
              position: absolute;
              top: 2px;
              left: 4.5px;
              font-size: 0.7rem;
              content: "\2714";
            }
          }
          .showAllFilters {
            font-weight: bold;
          }
          .showAllFilters:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .filtersList::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 6px;
          background-color: #fff;
        }
        .filtersList::-webkit-scrollbar {
          width: 8px;
          border-radius: 6px;
          background-color: #ececec;
        }
        .filtersList::-webkit-scrollbar-thumb {
          border-radius: 6px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #aaa;
        }
        ::-webkit-scrollbar-button {
          background-color: #ececec;
          color: #aaa;
          background-size: 10px 10px;
          background-repeat: no-repeat;
          background-position: center center;
          height: 16px;
          width: 1em;
        }
        ::-webkit-scrollbar-button:horizontal:increment {
          background-image: url(https://dl.dropboxusercontent.com/u/55165267/icon2.png);
        }
        ::-webkit-scrollbar-button:end:increment {
          background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTAsMTAxLjA4aDQwNC4zMDhMMjAyLjE1MSwzMDMuMjI5TDAsMTAxLjA4eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
        }
        ::-webkit-scrollbar-button:start:decrement {
          background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iYXJyb3ctZHJvcC11cCI+CgkJPHBvbHlnb24gcG9pbnRzPSIwLDE5MS4yNSAxMjcuNSw2My43NSAyNTUsMTkxLjI1ICAgIiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
        }
        .filterCursor {
          cursor: pointer;
          border-bottom: 2px solid #aaa;
        }

        #sortByFilterList {
          display: block;
          padding-top: 20px;
          padding-bottom: 20px;
          .sortByFilterListItem {
            border-bottom: none;
            .sortByFilterListItemContainer {
              border-bottom: none;
              .static-page-active {
                font-weight: bold;
                color: #000 !important;
              }
              .static-page-active::before {
                content: "> ";
              }
              a {
                padding: 0;
              }
              div {
                border-bottom: none;
              }
            }
            .sortByFilterListItemContainer:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        input[type="checkbox"] {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }

  #thirdRow {
    position: fixed;
    background-color: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-box-shadow: 1px 1px 6px #4a4a4f;
    box-shadow: 1px 1px 6px #4a4a4f;
    margin: 0;
    padding: 10px 0 5px;
    z-index: 10000;
    -webkit-transition: -webkit-transform 0.4s ease-in;
    transition: -webkit-transform 0.4s ease-in;
    -o-transition: transform 0.4s ease-in;
    transition: transform 0.4s ease-in;
    transition: transform 0.4s ease-in, -webkit-transform 0.4s ease-in;
    -webkit-transform: translateY(110%);
    -ms-transform: translateY(110%);
    transform: translateY(110%);
    #compareBtnCol {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      button {
        height: 40px;
        min-width: 145px;
        left: 50%;
        margin-bottom: 5px;
        color: white;
        border: none;
        font-size: 1.2em;
        border-radius: 6px;
        outline: none;
        -webkit-transition: background-color 0.15s ease-in;
        -o-transition: background-color 0.15s ease-in;
        transition: background-color 0.15s ease-in;
      }
      button:active {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      i {
        text-align: center;
      }
    }
  }

  .showCompare {
    -webkit-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    transform: translateY(0) !important;
  }

  .addToCartForm {
    input[type="number"] {
      height: 31px;
      width: 31% !important;
      max-width: 50px;
    }
  }

  .cartQty {
    height: 31px;
  }
  #cardCol {
    padding: 0;
    .card {
      margin-bottom: 20px;
      border: 2px solid #ccc;
      border-radius: 20px;
      padding: 10px;
      transition: border 0.2s ease-in, box-shadow 0.2s ease-in;
    }
    .card:hover {
      border: 2px solid $rkw-dark-blue;
      box-shadow: 0 0 10px $rkw-dark-blue;
    }
    .cardColLink {
      text-decoration: none;
    }
    .specialOfferSurrounding {
      position: relative;
      .specialOffer {
        position: absolute;
        background: -o-linear-gradient(90deg, #880000, #ff0000, #880000);
        background: linear-gradient(90deg, #880000, #ff0000, #880000);
        border-top: 2px dotted #710000;
        border-bottom: 2px dotted #710000;
        color: #fff;
        top: 4px;
        left: -38px;
        padding: 3px 18px;
        -webkit-transform: rotateZ(-45deg);
        transform: rotateZ(-45deg);
        -webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(25% 0%, 75% 0%, 100% 100%, 0% 100%);
      }
      .specialOfferBefore {
        position: absolute;
        top: 62.5px;
        left: -6.5px;
        height: 0px;
        width: 0px;
        border-bottom: 7px solid #710000;
        border-left: 7px solid transparent;
      }
      .specialOfferAfter {
        position: absolute;
        top: -7.5px;
        left: 62.5px;
        height: 0px;
        width: 0px;
        border-bottom: 7px solid #710000;
        border-left: 7px solid transparent;
      }
    }
    .imageContainer {
      position: relative;
      .productsImage {
        position: absolute;
        left: 0;
        width: auto;
        height: 100%;
        width: 100%;
        -webkit-transition: opacity 0.5s ease-in-out;
        -o-transition: opacity 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out;
      }
      .productsImageTop:hover {
        opacity: 0;
      }
    }
    .card-title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      background-color: #fff;
      font-size: 12px;
      font-weight: 600;
      margin: 0;
      padding: 3px 0;
      min-height: 66px;
      .mainTitleContainer {
        width: 100%;
        .brandCode {
          color: grey;
        }
        .mainTitle {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          min-height: 41px;
          p {
            font-family: montserrat-bold;
          }
        }
      }
    }

    .card-text {
      margin: 0;
      padding: 3px 0;
      #inStock {
        color: #25cc1b;
      }
      #notInStock {
        color: #d41a1a;
      }
      #limitedStock {
        color: #ffc136;
      }
    }
    .loginModal {
      color: green;
    }

    .textDivider {
      max-width: 160px;
      margin: auto;
      border-bottom: 1px solid $rkw-light-blue;
    }
    .textItemno {
      font-size: 0.8rem;
      font-weight: 600;
    }
    .textPrice {
      font-family: montserrat-bold;
    }
    form {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin: 3px 0;
      .form-label {
        margin-bottom: 0px;
        padding: 5px 0;
        font-size: 14px;
        text-transform: capitalize;
      }
      input {
        display: inline;
        float: left;
        font-size: 12px;
        width: 50%;
        max-width: 90px;
        padding: 0px;
        text-align: center;
      }
    }

    .linkToLogin {
      color: #388ebb;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .quickAddToBasket {
      position: absolute;
      width: 95%;
      height: 40px;
      bottom: -40px;
      left: 2.5%;
      opacity: 0;
      font-size: 14px;
      background-color: #69e21d;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition: -webkit-box-shadow 0.3s ease-in-out opacity 0.3s ease-in-out;
      transition: -webkit-box-shadow 0.3s ease-in-out opacity 0.3s ease-in-out;
      -o-transition: box-shadow 0.3s ease-in-out opacity 0.3s ease-in-out;
      transition: box-shadow 0.3s ease-in-out opacity 0.3s ease-in-out;
      transition: box-shadow 0.3s ease-in-out opacity 0.3s ease-in-out,
        -webkit-box-shadow 0.3s ease-in-out opacity 0.3s ease-in-out;
      z-index: -10;
    }
    .quickAddToBasket:hover,
    .quickAddToBasket:active,
    .quickAddToBasket:focus {
      background-color: #69e21d;
    }
    .quickAddToBasketActive {
      opacity: 1;
      z-index: 10;
      bottom: 10px;
    }

    .quickAddToBasketForm {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      .quickAddToBasketQty,
      .quickAddToBasketBundle {
        -ms-flex-preferred-size: 46%;
        flex-basis: 46%;
        height: 30px;
        margin-left: 2%;
        margin-right: 2%;
      }
      .quickAddToBasketAdd {
        display: block;
        margin-top: 5px;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
      }
    }

    #addToCartBtn {
      font-size: 12px;
      padding: 5px 0;
      width: 70px;
      -webkit-transition: -webkit-transform 0.1s ease-in;
      transition: -webkit-transform 0.1s ease-in;
      -o-transition: transform 0.1s ease-in;
      transition: transform 0.1s ease-in;
      transition: transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
    }
    #addToCartBtn:active {
      -webkit-transform: scale(0.98);
      -ms-transform: scale(0.98);
      transform: scale(0.98);
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    #noUnderline {
      border-bottom: 0px;
    }

    .compareContainer {
      margin: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      label {
        margin: 0;
        margin-right: 10px;
      }
      input {
        height: 20px;
        width: 20px;
      }
    }
  }

  .paginationCol {
    margin-top: 40px;
    ul {
      display: flex;
      justify-content: center;
      list-style-type: none;
      a {
        font-family: montserrat-bold;
        outline: none;
        padding: 10px;
        color: $rkw-dark-blue;
        cursor: pointer;
        font-size: 1.2rem;
      }
      .pageButton {
        outline: none;
        background-color: #d9dbdc;
        padding: 6px 10px;
        margin-left: 2px;
        margin-right: 2px;
      }
      .activePageButton {
        background-color: $rkw-dark-blue;
        color: #fff;
        pointer-events: disabled;
      }
      .iconGrey {
        opacity: 0.2;
        pointer-events: disabled;
        cursor: default;
      }
    }
  }

  .paginationTop {
    margin: 20px 0;
  }

  .paginationBottom {
    margin: 20px 0 40px;
  }

  .pagination-link-to-top {
    text-decoration: none;
  }

  // Extra Small (mobile, 380px and up)
  @media (min-width: 380px) {
    #firstRow {
      .form-group {
        width: 80%;
      }
      input {
        display: block;
      }
    }
  }

  @media (max-width: 579px) {
    .imageContainer {
      height: calc(49vw - 40px);
    }
  }

  // Small (Small tablet, 580px and up)
  @media (min-width: 580px) {
    .imageContainer {
      height: 226px;
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    #firstRow {
      .form-group {
        width: 47%;
      }
    }
    #secondRow {
      .imageContainer {
        height: 202px;
      }
      .card-img {
        width: 100%;
      }
    }
  }

  @media (min-width: 992px) {
    #firstRow {
      .form-group {
        width: 47%;
      }
      #buttonContainer {
        justify-content: flex-end;
        .sortItems {
          height: 26px;
        }
        button {
          display: none;
        }
      }
    }
    #secondRow {
      .card-img {
        width: 100%;
      }
    }
  }

  @media (min-width: 1200px) {
    #firstRow {
      .form-group {
        width: 47%;
      }
    }
    .filterList {
      .filterItemName {
        font-size: 0.73rem;
      }
    }
    #secondRow {
      .imageContainer {
        height: 173px;
      }
      .card-img {
        width: 100%;
      }
    }
  }
}

// simply home section
#simplyHomeContainer {
  .simplyHomeHeader {
    background-color: #56504d;
    color: white;
    margin-bottom: 15px;
  }
  .simplyHomeHeaderImage {
    width: 100%;
    padding: 5px;
  }
  .shadow {
    filter: brightness(0.5);
  }
  .blackBorderFirst {
    height: 5px;
    background-color: black;
    margin-bottom: 5px;
    margin-top: 125px;
  }
  .blackBorderTop {
    height: 5px;
    background-color: black;
    margin-bottom: 5px;
  }
  .blackBorder {
    height: 5px;
    background-color: black;
    margin-bottom: 5px;
    max-width: 10px;
  }
  .blackBorderOuter {
    height: 5px;
    background-color: black;
    margin-bottom: 5px;
    width: 100%;
  }
  .categoryTile {
    position: relative;
    text-align: center;
    color: white;
  }
  .bottomLeft {
    position: absolute;
    bottom: 2px;
    left: 8px;
    font-weight: bold;
  }

  .linkToClick {
    color: white;
  }

  @media (max-width: 500px) {
    .logo {
      max-height: 50px;
    }
    .bottomLeftTextSize {
      font-size: 20px;
    }
  }

  @media (min-width: 501px) {
    .logo {
      max-width: 160px;
    }
    .bottomLeftTextSize {
      font-size: 20px;
    }
  }

  @media (min-width: 992px) {
    .greyBorder {
      background-color: #56504d;
      margin-bottom: 5px;
      height: 125px;
      color: white;
    }
    .simplyHomeBorderText {
      font-size: 12px;
    }
    .logo {
      max-width: 160px;
    }
    .bottomLeftTextSize {
      font-size: 20px;
    }
  }

  @media (min-width: 1920px) {
    .greyBorder {
      background-color: #56504d;
      margin-bottom: 5px;
      height: 200px;
      color: white;
    }
    .simplyHomeBorderText {
      font-size: 15px;
      max-width: 1200px;
    }
    .logo {
      max-width: 180px;
    }
    .bottomLeftTextSize {
      font-size: 28px;
    }
  }

  @media (min-width: 2560px) {
    .greyBorder {
      background-color: #56504d;
      margin-bottom: 5px;
      height: 275px;
      color: white;
    }

    .simplyHomeBorderText {
      font-size: 22px;
      max-width: 1200px;
    }
    .logo {
      max-width: 300px;
    }
    .bottomLeftTextSize {
      font-size: 35px;
    }
  }
  @media (min-width: 3840px) {
    .greyBorder {
      background-color: #56504d;
      margin-bottom: 5px;
      height: 400px;
      color: white;
    }

    .simplyHomeBorderText {
      font-size: 30px;
      max-width: 1500px;
    }
    .logo {
      max-width: 400px;
    }
    .bottomLeftTextSize {
      font-size: 50px;
    }
  }
}
