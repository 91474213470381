#aboutUsPanel {
  margin-bottom: 20px;
  .aboutUsRow {
    background-position: center center;
    height: 380px;
    position: relative;
    .colourOverlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #032c62c2;
    }
    .aboutUsCol {
      color: #fff;
      .aboutUsButton {
        border: 2px solid #fff;
        background-color: transparent;
        color: #fff;
        height: 30px;
        width: 80px;
        border-radius: 15px;
        font-size: .9rem;
        transition: transform .1s ease-in;;
      }
      .aboutUsButton:hover {
        transform: scale(1.05);
      }
    }
  }
}

@media only screen and (min-width: 991.98px) {
  .colourOverlay {
    background: linear-gradient(90deg, $rkw-dark-blue, $rkw-dark-blue, $rkw-dark-blue, transparent, transparent) !important;
  }
}