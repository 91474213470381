/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

#retailerCarousel {
    margin: auto;
    padding-bottom: 4.0vw;
    .imageSurrounding {
        height: 6vw;
        min-height: 75px;
        .retailerImage {
            height: 100%;
            margin: auto;
            padding: 10px 0;
        }
    }
    .slick-prev:before {
        position: absolute;
        top: 2px;
        left: 25px;
        font-family: 'slick';
        font-size: 30px;
        line-height: 50%;
        margin-left: -2%;
        opacity: 99.75;
        color: rgb(79, 153, 190);
        padding: 0;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
    }
    .slick-next:before {
        position: absolute;
        top: 2px;
        right: 25px;
        font-family: 'slick';
        font-size: 30px;
        line-height: 50%;
        margin-left: -2%;
        opacity: 99.75;
        color: rgb(79, 153, 190);
        padding: 0;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
    }
}