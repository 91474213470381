/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

@import url("https://fonts.googleapis.com/css?family=Exo:100,200,300,400,500,600,700");

#HomePageCarousel {
  width: 100% !important;
  max-width: unset !important;
  .homepageLinksRow {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    .homepagePageLinkCol {
      cursor: pointer;
      a {
        text-decoration: none;
      }
      .backgroundImage {
        height: 43vw;
      }
      .homepagePageLinkTitle {
        background-color: $rkw-dark-blue;
        color: #fff;
        text-align: center;
        padding: 5px;
        p {
          margin-bottom: 5px;
        }
        .homepagePageLinkTitleDivider {
          margin: 0;
          height: 2px;
          background-color: $rkw-light-blue;
          transition: width 0.3s ease-in;
        }
      }
      .homepagePageLinkTitle1 {
        margin-bottom: 4px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  #HomePageCarousel {
    .backgroundImage {
      height: 33vw !important;
    }
  }
}

@media only screen and (min-width: 992px) {
  #HomePageCarousel {
    margin-bottom: 20px;
    .carousel-control-prev-icon {
      position: absolute;
      left: 0;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 -6 8 20'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e") !important;
      padding: 20px;
      background-color: #4a4a4f;
    }
    .carousel-control-next-icon {
      position: absolute;
      right: 0;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 -6 8 20'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e") !important;
      padding: 20px;
      background-color: #4a4a4f;
    }
    .carousel-indicators {
      margin-bottom: 0;
    }
    .carousel-indicators li {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #7f7f7f;
      margin-left: 5px;
    }
    .carousel-indicators .active {
      background-color: $rkw-dark-blue;
    }
    .homepagePageLinkCol {
      .backgroundImage {
        height: calc(13.75vw - 41px) !important;
      }
    }
  }
}
