#aboutUs {
    margin-top: 3rem;
    .aboutUsText {
        margin: 40px 10px;
        font-size: .9rem;
    }
    .whyRKWSurrounding {
        h4 {
            color: $rkw-dark-blue;
            font-weight: 600;
        }
        .whyRKWDivider {
            width: 60px;
            height: 2px;
            background-color: $rkw-light-blue;
        }
    }
    .whyRKWList {
        span {
            font-size: .9rem;
            padding: 10px;
        }
        .whyRKWListDivider {
            color: $rkw-light-blue;
        }
    }
    .aboutUsImageCol6 {
        height: 35vw;
        max-height: 325px;
        background-size: cover !important;
        background-position: center top !important;
    }
    .aboutUsImageCol4 {
        height: 35vw;
        max-height: 325px;
        background-size: cover !important;
        background-position: center top !important;
    }
    .insideAboutUsDivider {
        background-color: $rkw-dark-blue;
        width: 100%;
        height: 2px;
    }
    .featuredDivider {
        margin-top: 4rem;
        background-color: $rkw-light-blue;
        width: 100%;
        height: 2px;
    }
    .insideAboutUsText,
    .featuredText {
        top: -34px;
        background-color: #fff;
        padding: 0 5px;
        font-weight: 700;
        font-size: 1.3rem;
        font-family: montserrat-bold;
    }
    .aboutUsCol {
        cursor: pointer;
        a {
            text-decoration: none;
        }
        .aboutUsTitle {
            background-color: $rkw-dark-blue;
            color: #fff;
            text-align: center;
            padding: 10px;
            p {
            margin-bottom: 10px;
            }
            .aboutUsTitleDivider {
            margin: 0;
            height: 2px;
            background-color: $rkw-light-blue;
            transition: width .3s ease-in;
            }
        }
    }
}

@media only screen and (min-width:768px) {
    #aboutUs {
        .aboutUsImageCol6 {
            height: 21vw;
        }
        .aboutUsImageCol4 {
            height: 21vw;
        }
    }
}

@media only screen and (min-width:992px) {
    #aboutUs {
        .aboutUsImageCol4 {
            height: 16vw;
        }
    }
}