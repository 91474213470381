.Box {
	display: inline-block;
	width: 32px;
	height: 50px;
	border-radius: 3px;
	font-size: 40px;
	padding: 0;
	position: relative;
	margin-bottom: .25em;
	vertical-align: top;
	transition: .3s color, .3s border, .3s transform, .3s opacity;
}


[class*="Loader"] {
	display: inline-block;
	width: 1em;
	height: 1em;
	color: inherit;
	vertical-align: middle;
	pointer-events: none;
}


.Loader {
	transform: rotateZ(45deg);
	perspective: 1000px;
	border-radius: 50%;
	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: inherit;
		height: inherit;
		border-radius: 50%;
		animation: 1s spin linear infinite;
	}
	&:before {
		transform: rotateX(70deg);
	}
	&:after {
		transform: rotateY(70deg);
		animation-delay: .4s;
	}
}

@keyframes rotate {
	0% {
		transform: translate(-50%, -50%) rotateZ(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotateZ(360deg);
	}
}

@keyframes rotateccw {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(-360deg);
	}
}

@keyframes spin {
	0%,
	100% {
		box-shadow: .2em 0px 0 0px $rkw-dark-blue;
	}
	12% {
		box-shadow: .2em .2em 0 0 $rkw-dark-blue;
	}
	25% {
		box-shadow: 0 .2em 0 0px $rkw-dark-blue;
	}
	37% {
		box-shadow: -.2em .2em 0 0 $rkw-dark-blue;
	}
	50% {
		box-shadow: -.2em 0 0 0 $rkw-dark-blue;
	}
	62% {
		box-shadow: -.2em -.2em 0 0 $rkw-dark-blue;
	}
	75% {
		box-shadow: 0px -.2em 0 0 $rkw-dark-blue;
	}
	87% {
		box-shadow: .2em -.2em 0 0 $rkw-dark-blue;
	}
}
