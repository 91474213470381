/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

$border: #c5c5c5;

.filterDropdownTitleRow {
    padding: 0 1rem;
    .filterDropdownTitleCol {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        border-bottom: 1px solid $border;
        border-top: 1px solid $border;
        cursor: pointer;
        .dropdownIcon {
            color: grey;
        }
    }
}

.clearList {
    color: grey;
    cursor: pointer;
    padding: 5px 10px;
}

.dropdownClose {
    height: 0;
    display: none;
    -webkit-transition: height .3s ease-in, display .3s ease-in;
    -o-transition: height .3s ease-in, display .3s ease-in;
    transition: height .3s ease-in, display .3s ease-in;
}

.mobileFilterListItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    list-style-type: none;
    input {
        cursor: pointer;
    }
}

