#ourShowRooms {
    margin-top: 3rem;
    .ourShowRoomsText {
        text-align: center;
        font-size: .9rem;
    }
    .countryLine {
        position: absolute;
        top: 1px;
        width: 100%;
        background-color: #000;
        height: 4px;
    }
    .countryInformationRow {
        .heading {
            font-family: montserrat-bold;
            width: 200px;
            margin: auto;
            height: 40px;
            border: 4px solid #000;
            background-color: #fff;
            border-radius: 20px;
            color: $rkw-light-blue;
        }
        .subHeading {
            font-family: montserrat-bold;
            margin-top: 1rem;
            font-size: 1rem;
        }
        ul {
            padding: 0;
            list-style-type: none;
            font-size: .9rem;
        }
        .clickToEnlargeText {
            text-align: center;
            color: #9d9d9d;
            font-size: .8rem;
            .enlargeImageIcon {
                width: 12px;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .ukRow {
        padding-left: calc(1.87vw + 1.5rem);
        padding-right: calc(1.87vw + 1.5rem);
    }
    .chinaRow {
        padding-left: 15px;
        padding-right: 15px;
    }
}