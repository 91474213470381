/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

.quickAddToBasketForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .quickAddToBasketQty,
  .quickAddToBasketBundle {
    width: 46%;
    height: 30px;
    margin-left: 2%;
    margin-right: 2%;
  }
}