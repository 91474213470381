.addToCartForm {
    display: block !important;
    margin-top: 20px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
        height: 40px;
        width: 40px !important;
        margin-left: 10px;
        text-align: center;
    }
    .adjustQuantity {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #4a4a4f;
        height: 40px;
        width: 40px;
        cursor: pointer;
    }
    .addToBasketHeading {
        position: absolute;
        top: -25px;
        font-size: 1rem;
        color: #777777;
    }
    .addToCartInformation {
        p {
            margin-bottom: 0;
            color: #777;
            font-size: 14px !important;
        }
        h3 {
            font-size: 14px !important;
            margin-bottom: 0px !important;
            font-family: "montserrat-regular" !important;
        }
    }
    #addToCartBtn {
        background-color: $rkw-dark-blue;
    }
    .bundleType {
        margin-right: 10px;
        height: 40px;
        width: 100%;
        transition: all 0.1s ease-in;
    }
    .selectNotValid {
        border: 1px solid red;
        box-shadow: 0 0 8px red;
    }
    .selectNotValidText {
        color: red;
    }
}

@media only screen and (min-width: 575px) {
    .bundleType {
        width: 150px;
    }
}
