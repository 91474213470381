#firstTimeLogin {
    height: 50vh;
    padding-top: 40px;
    padding-bottom: 40px;
}

.firstTimePassword {
    display: none;
}

.firstTimePasswordNotify {
    display: block !important;
    color: red;
}

.errorSection {
    background-color: rgba(255, 0, 0, 0.3);
    border: 1px solid red;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    p {
        font-size: 1.2rem;
    }
}