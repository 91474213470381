.wowOffers {
    font-size: 12px;
    text-align: start;
    padding-top: 0.1px;
    padding-bottom: 30px;
    .image {
        .imageHeading {
            position: absolute;
            background-color: #032c62c2;
            height: 125%;
            color: #fff;
            z-index: 1;
            div {
                h2 {
                    font-family: montserrat-bold;
                    border-bottom: 3px solid $rkw-light-blue;
                }
                p {
                    width: 80%;
                    text-align: center;
                }
            }
        }
        .imageRight {
            position: relative;
            div {
                .imageOverlay {
                    position: absolute;
                    background: transparent;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}

// Large devices (desktop, 992px and up)
@media only screen and (min-width: 992px) {
    .wowOffers {
        .image {
            .imageHeading {
                position: static !important;
                background-color: $rkw-dark-blue !important;
                height: unset !important;
            }
            .imageRight {
                .imageOverlay {
                    background: linear-gradient(
                        90deg,
                        $rkw-dark-blue,
                        transparent,
                        transparent,
                        transparent
                    ) !important;
                }
            }
        }
    }
}
