/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

#PlanForGrowth {
    padding-bottom: 100px;
    .planForGrowthPitchText {
        width: 90%;
        max-width: 800px;
    }
    .robAndSuSection {
        background-color: #263642;
        border-top: 4px solid #4c93b7;
        border-bottom: 4px solid #4c93b7;
        color: #fff;
        .robAndSuSectionCol {
            padding: 4%;
        }
        .robAndSuImageSurrounding {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            background-color: #ccc;
            border: 7px solid #fff;
            width: 90%;
            max-width: 350px;
            max-height: 238px;
            margin: auto;
            -webkit-transform: rotate(3deg);
                -ms-transform: rotate(3deg);
                    transform: rotate(3deg);
            -webkit-box-shadow: 3px 3px 5px #000;
                    box-shadow: 3px 3px 5px #000;
            img {
                -o-object-fit: contain;
                   object-fit: contain;
            }
        }
    }
    .reasonsForRKW {
        .reasonsForRKWImage {
            height: 150px;
            shape-outside: circle();
        }
        .reasonsForRKWImage1 {
            float: left;
        }
        .reasonsForRKWImage2 {
            float: right;
        }
        p {
            text-align: justify;
            -ms-flex-preferred-size: 1;
                flex-basis: 1;
        }
    }
    .butNowContainerDesktop {
        height: 37vw;
        background-size: cover !important;
        .butNowRow {
            .butNowText {
                color: #4c93b7;
                text-shadow: 0 0 1px #4c93b7;
                font-size: 1.45vw;
                margin-bottom: 50px;
            }
        }
        .butNowQuoteCol {
            font-size: 1.18vw;
            p {
                color: #263642;
                font-size: 1.25vw;
            }
        }
    }
    .butNowContainerMobile {
        .butNowMainImage {
            height: 76vw;
        }
        .butNowSecondaryImage {
            max-width: 200px;
            max-height: 200px;
        }
        .butNowQuoteCol {
            font-size: 1.02rem;
            line-height: 1.2rem;
            p {
                color: #263642;
                font-size: 1.15rem;
            }
        }
    }
    .forRetailers {
        width: 90%;
        max-width: 800px;
    }
    .perfectTimeContainer {
        background: -webkit-gradient(linear, left top, left bottom, from(#396b86), to(#58abd5));
        background: -o-linear-gradient(#396b86, #58abd5);
        background: linear-gradient(#396b86, #58abd5);
        border-top: 4px solid #263642;
        border-bottom: 4px solid #263642;
        color: #fff;
        .perfectTimeCol {
            padding: 30px;
            button {
                background-color: #263642;
                color: #fff;
                font-weight: bold;
                width: 90%;
                max-width: 200px;
                -webkit-box-shadow: 2px 2px 5px #4f4f4a;
                        box-shadow: 2px 2px 5px #4f4f4a;
                -webkit-transition: -webkit-transform .07s ease-in, -webkit-box-shadow .07s ease-in;
                transition: -webkit-transform .07s ease-in, -webkit-box-shadow .07s ease-in;
                -o-transition: transform .07s ease-in, box-shadow .07s ease-in;
                transition: transform .07s ease-in, box-shadow .07s ease-in;
                transition: transform .07s ease-in, box-shadow .07s ease-in, -webkit-transform .07s ease-in, -webkit-box-shadow .07s ease-in;
            }
            button:active {
                -webkit-transform: scale(.98);
                    -ms-transform: scale(.98);
                        transform: scale(.98);
                -webkit-box-shadow: none;
                        box-shadow: none;
            }
            .formLink {
                color: white;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
    .lineBreak {
        width: 90%;
        max-width: 200px;
        height: 2px;
        background-color: #4c93b7;
        border-radius: 50%;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    p {
        font-size: 1rem;
    }


    @media screen and (min-width: 992px) {
        .reasonsForRKW {
            .reasonsForRKWImage {
                height: 210px;
            }
        }
        .robAndSuImageSurrounding {
            max-width: 440px !important;
            max-height: 298px !important;
        }
    }
}

