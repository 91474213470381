#passwordResetConfirmation {
    padding-top: 40px;
    padding-bottom: 40px;
    height: 61vh;
    .passwordResetConfirmationRow {
        width: 80%;
        max-width: 800px;
        margin: auto;
        .passwordResetConfirmationMainHeading {
            padding: 15px;
            background-color: #b4ff00a6;
            border: 1px solid #2e5f2e61;
            color: #2e5f2e;
            border-radius: 7px;
        }
        .backToHomePage {
            margin-bottom: 50px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .passwordResetConfirmationRow {
        width: 90%;
    }
}