/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

#individualOrders {
    padding-top: 40px;
    padding-bottom: 60px;
    .orderSummaryHeading {
        font-family: montserrat-bold;
        text-align: center;
    }
    .summaryItems {
        border-radius: 10px;
        padding: 20px;
        .summaryItem {
            border: 3px solid $rkw-dark-blue;
            border-radius: 20px;
            .grandTotal {
                font-size: 1.05rem;
            }
            .card-title {
                font-family: montserrat-bold;
                padding: 20px 10px;
                border-bottom: 3px solid $rkw-dark-blue;
                .card-title-row {
                    font-size: 0.9rem;
                }
            }
            .summaryImage {
                width: 100px;
            }
            .summaryItemno {
                color: grey;
                margin-bottom: .2rem;
            }
            .summaryDescription {
                font-size: 0.9rem;
                font-weight: bold;
                margin-bottom: .2rem;
            }
            .summaryPrice {
                font-size: 0.91rem;
                margin-bottom: .2rem;
            }
            .addToBasketAgain,
            .addAllToBasketAgain {
                background-color: $rkw-light-blue;
                font-size: .7rem;
                border-radius: 5px;
                border: none;
                color: #fff;
                padding: 3px 7px;
                width: 103px;
                position: relative;
            }
            .addToBasketAgainUpdater {
                display: none;
            }
            .addAllToBasketAgainUpdater {
                display: none;
            }
            
            .addToBasketAgainUpdaterShow {
                display: block;
                background-color: #69e21d;
                right: 20px;
                bottom: unset;
                height: 24px;
                font-size: .7rem;
                border-radius: 5px;
                border: none;
                color: #fff;
                z-index: -100000;
                width: 123px;
                -webkit-animation: updateBasket 0.5s forwards 1;
                    animation: updateBasket 0.5s forwards 1;
            }
            .addAllToBasketAgainUpdaterShow {
                display: block;
                background-color: #69e21d;
                right: 43.6%;
                bottom: 12px;
                height: 37px;
                z-index: -100000;
                -webkit-animation: updateBasket 0.5s forwards 1;
                    animation: updateBasket 0.5s forwards 1;
            }
        }
    }
    @media only screen and (max-width: 374.98px) {
        .addToBasketAgainUpdaterShow{
            right: 26% !important;
            bottom: 17px !important;
        }
        .addAllToBasketAgainUpdaterShow {
            right: 28% !important;
        }
    }
    @media only screen and (min-width: 375px) and (max-width: 499.98px) {
        .addToBasketAgainUpdaterShow{
            right: 34% !important;
            bottom: 18px !important;
        }
        .addAllToBasketAgainUpdaterShow {
            right: 32% !important;
        }
    }
    @media only screen and (min-width: 500px) and (max-width:767.98px) {
        .addToBasketAgainUpdaterShow{
            right: 37% !important;
            bottom: 19px !important;
        }
        .addAllToBasketAgainUpdaterShow {
            right: 35.5% !important;
        }
    }
    @media only screen and (min-width:768px) and (max-width:991.98px) {
        .addToBasketAgainUpdaterShow{
            right: 41% !important;
            bottom: 19px !important;
        }
        .addAllToBasketAgainUpdaterShow {
            right: 40% !important;
        }
    }
    @media only screen and (min-width:992px) and (max-width:1199.98px) {
        .addAllToBasketAgainUpdaterShow {
            right: 42.5% !important;
        }
    }
    // Extra Large(anything 1200px & up)
    @media only screen and (min-width:1200px) {
        .card-title-row {
            font-size: 1rem !important;
        }
    }

    @-webkit-keyframes updateBasket {
        0% {
            z-index: -100000;
            -webkit-box-shadow: none;
                    box-shadow: none;
            opacity: 0;
        }
        100% {
            z-index: 1000;
            -webkit-box-shadow: 2px 2px 9px #69e21d;
                    box-shadow: 2px 2px 9px #69e21d;
            opacity: 1;
        }
    }
}

